/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as e } from "../chunks/tslib.es6.js";
import { property as t } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/Logger.js";
import "../core/RandomLCG.js";
import { subclass as s } from "../core/accessorSupport/decorators/subclass.js";
import o from "./Widget.js";
import r from "./Compass/CompassViewModel.js";
import { css as i } from "./Compass/css.js";
import { loadCalciteComponents as n } from "./support/componentsUtils.js";
import { globalCss as a } from "./support/globalCss.js";
import "./support/widgetUtils.js";
import { messageBundle as l } from "./support/decorators/messageBundle.js";
import { tsx as p } from "./support/jsxFactory.js";
let c = class extends o {
  constructor(e, t) {
    super(e, t), this.messages = null, this.viewModel = new r(), this._reset = () => {
      this.viewModel.reset();
    }, this._toRotationTransform = e => ({
      transform: `rotateZ(${e.z}deg)`
    });
  }
  loadDependencies() {
    return n({
      button: () => import("@esri/calcite-components/dist/components/calcite-button.js"),
      icon: () => import("@esri/calcite-components/dist/components/calcite-icon.js")
    });
  }
  get goToOverride() {
    return this.viewModel.goToOverride;
  }
  set goToOverride(e) {
    this.viewModel.goToOverride = e;
  }
  get icon() {
    return "rotation" === this.viewModel.state ? "arrow-up" : "compass-needle";
  }
  set icon(e) {
    this._overrideIfSome("icon", e);
  }
  get label() {
    return this.messages?.widgetLabel ?? "";
  }
  set label(e) {
    this._overrideIfSome("label", e);
  }
  get view() {
    return this.viewModel.view;
  }
  set view(e) {
    this.viewModel.view = e;
  }
  reset() {
    return this.viewModel.reset();
  }
  render() {
    const {
        orientation: e,
        state: t
      } = this.viewModel,
      {
        messages: s
      } = this;
    return p("div", {
      class: this.classes(i.base, a.widget)
    }, p("calcite-button", {
      class: a.widgetButton,
      disabled: "disabled" === t,
      kind: "neutral",
      label: s.reset,
      onclick: this._reset,
      round: !0,
      scale: "s",
      title: s.reset
    }, p("div", {
      "aria-hidden": "true",
      class: i.iconContainer,
      title: s.reset
    }, p("calcite-icon", {
      icon: this.icon,
      styles: this._toRotationTransform(e)
    }))));
  }
};
e([t()], c.prototype, "goToOverride", null), e([t()], c.prototype, "icon", null), e([t()], c.prototype, "label", null), e([t(), l("esri/widgets/Compass/t9n/Compass")], c.prototype, "messages", void 0), e([t()], c.prototype, "view", null), e([t({
  type: r
})], c.prototype, "viewModel", void 0), c = e([s("esri.widgets.Compass")], c);
const d = c;
export { d as default };