/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import t from "../../../../core/Handles.js";
import { watch as i, initial as e } from "../../../../core/reactiveUtils.js";
import { addFrameTask as a } from "../../../../core/scheduling.js";
import { translateBy as s, scaleAndRotateBy as n } from "../../viewpointUtils.js";
import { InputHandler as r } from "../../../input/InputHandler.js";
import { resetTransformation as o, extractTransformation as h, isZeroTransformation as l } from "../../../navigation/gamepadAndKeyboardUtils.js";
class d extends r {
  constructor(i) {
    super(!0), this._view = i, this._frameTask = null, this._watchHandles = new t(), this._currentDevice = null, this._transformation = {
      translation: [0, 0, 0],
      heading: 0,
      tilt: 0,
      zoom: 0
    }, this._handle = this.registerIncoming("gamepad", t => this._handleGamePadEvent(t)), this._handle.pause();
  }
  onInstall(t) {
    super.onInstall(t), this._watchHandles.add([i(() => this._view.navigation.gamepad?.enabled, t => {
      t ? (this._handle.resume(), this._frameTask || (this._frameTask = a({
        update: t => this._frameUpdate(t.deltaTime)
      }))) : (this._handle.pause(), this._frameTask && (this._frameTask.remove(), this._frameTask = null));
    }, e)]);
  }
  onUninstall() {
    this._watchHandles.removeAll(), this._frameTask && (this._frameTask.remove(), this._frameTask = null), super.onUninstall();
  }
  _handleGamePadEvent(t) {
    const i = this._view.navigation.gamepad.device;
    i && t.data.device !== i || this._currentDevice && this._currentDevice !== t.data.device || ("end" === t.data.action ? (this._currentDevice = null, o(this._transformation)) : (this._currentDevice = t.data.device, h(t.data, this._view.navigation.gamepad, this._transformation)));
  }
  _frameUpdate(t) {
    const i = this._transformation;
    if (l(i)) return;
    const e = this._view.viewpoint.clone(),
      a = this._view.navigation.gamepad.velocityFactor,
      r = _ * a * t;
    s(e, e, [i.translation[0] * r, -i.translation[1] * r]);
    const o = 1 + i.translation[2] * c * t,
      h = this._view.constraints.rotationEnabled ? -i.heading * m * t : 0,
      d = this._view.size,
      v = [d[0] / 2, d[1]];
    n(e, e, o, h, v, d);
    const p = this._view.constraints.constrain(e, this._view.viewpoint);
    this._view.viewpoint = p;
  }
}
const m = .06,
  _ = .7,
  c = 6e-4;
export { d as GamepadNavigation };