/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as t } from "../chunks/tslib.es6.js";
import { property as e } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/Logger.js";
import "../core/RandomLCG.js";
import { subclass as o } from "../core/accessorSupport/decorators/subclass.js";
import i from "./Widget.js";
import { css as s } from "./NavigationToggle/css.js";
import r from "./NavigationToggle/NavigationToggleViewModel.js";
import { loadCalciteComponents as l } from "./support/componentsUtils.js";
import { globalCss as a } from "./support/globalCss.js";
import { setFocus as n } from "./support/widgetUtils.js";
import { messageBundle as p } from "./support/decorators/messageBundle.js";
import { tsx as g } from "./support/jsxFactory.js";
let c = class extends i {
  constructor(t, e) {
    super(t, e), this.messages = null, this.viewModel = new r(), this.toggle = () => this.viewModel.toggle(), this._panButton = null, this._rotateButton = null, this._toggle = () => {
      const t = "pan" === this.viewModel?.navigationMode ? this._rotateButton : this._panButton;
      n(t), this.toggle();
    };
  }
  loadDependencies() {
    return l({
      button: () => import("@esri/calcite-components/dist/components/calcite-button.js")
    });
  }
  get icon() {
    return "move";
  }
  set icon(t) {
    this._overrideIfSome("icon", t);
  }
  get label() {
    return this.messages?.widgetLabel ?? "";
  }
  set label(t) {
    this._overrideIfSome("label", t);
  }
  set layout(t) {
    "horizontal" !== t && (t = "vertical"), this._set("layout", t);
  }
  get view() {
    return this.viewModel.view;
  }
  set view(t) {
    this.viewModel.view = t;
  }
  render() {
    const t = "disabled" === this.viewModel?.state,
      e = "pan" === this.viewModel?.navigationMode,
      o = this.messages.toggle;
    return g("div", {
      class: this.classes(s.base, a.widget, {
        [s.isLayoutHorizontal]: "horizontal" === this.layout
      })
    }, g("calcite-button", {
      afterCreate: t => {
        this._panButton = t;
      },
      appearance: e ? "outline-fill" : "solid",
      class: a.widgetButton,
      disabled: t,
      iconStart: "move",
      kind: "neutral",
      label: o,
      onclick: this._toggle,
      tabIndex: e ? void 0 : -1,
      title: o
    }), g("calcite-button", {
      afterCreate: t => {
        this._rotateButton = t;
      },
      appearance: e ? "solid" : "outline-fill",
      class: a.widgetButton,
      disabled: t,
      iconStart: "rotate",
      kind: "neutral",
      label: o,
      onclick: this._toggle,
      tabIndex: e ? -1 : void 0,
      title: o
    }));
  }
};
t([e()], c.prototype, "icon", null), t([e()], c.prototype, "label", null), t([e({
  value: "vertical"
})], c.prototype, "layout", null), t([e(), p("esri/widgets/NavigationToggle/t9n/NavigationToggle")], c.prototype, "messages", void 0), t([e()], c.prototype, "view", null), t([e({
  type: r
})], c.prototype, "viewModel", void 0), c = t([o("esri.widgets.NavigationToggle")], c);
const d = c;
export { d as default };