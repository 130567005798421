/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as p } from "../chunks/tslib.es6.js";
import { createTask as e } from "../core/asyncUtils.js";
import t from "../core/Error.js";
import has from "../core/has.js";
import o from "../core/Logger.js";
import { throwIfAborted as i, wrapAbortWithTimeout as s, allSettledValues as r, createResolver as a } from "../core/promiseUtils.js";
import { watch as u, initial as n, whenOnce as c } from "../core/reactiveUtils.js";
import { property as h } from "../core/accessorSupport/decorators/property.js";
import "../core/RandomLCG.js";
import { subclass as l } from "../core/accessorSupport/decorators/subclass.js";
import { ViewEventPriorities as d } from "./input/InputManager.js";
function m(p) {
  return null != p && "open" in p && "declaredClass" in p;
}
function f(p) {
  return null != p && "declaredClass" in p && "dockOptions" in p;
}
const w = a => {
  let w = class extends a {
    constructor() {
      super(...arguments), this._popupSetupTask = null, this.popup = {}, this.popupEnabled = !0;
    }
    initialize() {
      this.addHandles([u(() => [this.ui, this.popup], ([p, e], t) => {
        const o = "popup",
          i = "manual";
        if (t) {
          const [p, e] = t;
          p && m(e) && (e.view = null, f(e) && p.remove(e, o));
        }
        p && m(e) && (e.view = this, f(e) && p.add(e, {
          key: o,
          position: i,
          internal: !0
        }));
      }, n), this.on("click", p => {
        this.popup && this.popupEnabled && ("mouse" !== p.pointerType || 0 === p.button) && (m(this.popup) ? this.popup.viewModel.handleViewClick(p) : p.async(async () => {
          await this.setupPopup(), m(this.popup) && !this.destroyed && this.ready && this.popupEnabled && this.popup.viewModel.handleViewClick(p);
        }));
      }, d.WIDGET)]), c(() => this.ready && this.popupEnabled && !this.updating).then(() => {
        import("../widgets/Popup.js");
      });
    }
    destroy() {
      this.destroyed || this.closePopup();
    }
    async openPopup(p) {
      if (m(this.popup)) return this.popup.open(p);
      try {
        if (await this.setupPopup(), !this.popup) return void o.getLogger(this).error(new t("view:null-popup", "Popup is null and can't be opened"));
        this.popup.open(p);
      } catch {}
    }
    closePopup() {
      this._popupSetupTask?.abort(), m(this.popup) && this.popup.close();
    }
    async fetchPopupFeatures(p, e) {
      return await this.when(), this._popupHitsToFeatures(await this._getPopupHits(p, e), e);
    }
    async setupPopup() {
      if (this._popupSetupTask?.abort(), this.popup && !m(this.popup)) return this._popupSetupTask = e(async p => {
        const {
          default: e
        } = await import("../widgets/Popup.js");
        if (i(p), !this.popup || m(this.popup)) return;
        const t = this.popup;
        delete t.open, delete t.close, this.popup = new e(t);
      }), this._popupSetupTask.promise;
    }
    async _popupHitsToFeatures({
      location: p,
      hits: e
    }, t) {
      const o = [],
        i = [];
      let a = !1;
      const u = s(t, has("popup-view-fetch-timeout") ?? P),
        n = p => {
          const e = new y(p);
          return i.push(e), o.push(e.promise), e;
        },
        c = p => {
          const e = i.at(-1);
          return e && e.layerView === p && !a ? e : n(p);
        };
      for (const s of e) if ("graphic" in s) {
        c(s.layerView).graphics.push(s.graphic), a = !1;
      } else o.push(s.layerView.fetchPopupFeaturesAtLocation(s.mapPoint, u)), a = !0;
      i.map(p => p.resolve(u));
      const h = r(o).then(p => p.filter(p => !!p).flat());
      return {
        pendingFeatures: o,
        allGraphicsPromise: h,
        location: p
      };
    }
    async _getPopupHits(p, e) {
      const {
        hits: t,
        location: o
      } = await this.popupHitTest(p);
      i(e);
      const s = [];
      for (const i of t) if ("graphic" in i) {
        if (this._isValidPopupGraphic(i.graphic, e)) {
          const p = this._isValidPopupGraphicsLayerView(i.layerView) ? i.layerView : void 0;
          s.push({
            graphic: i.graphic,
            layerView: p
          });
        }
      } else this._isValidPopupLocationLayerView(i.layerView) && s.push({
        mapPoint: i.mapPoint,
        layerView: i.layerView
      });
      return {
        hits: s,
        location: o
      };
    }
    _isValidPopupGraphic(p, e) {
      return p && !!p.getEffectivePopupTemplate(e?.defaultPopupTemplateEnabled);
    }
    _isValidPopupGraphicsLayerView(p) {
      return !p || (!("layer" in p) || !p.suspended) && "fetchPopupFeaturesFromGraphics" in p;
    }
    _isValidPopupLocationLayerView(p) {
      return (!("layer" in p) || !p.suspended) && "fetchPopupFeaturesAtLocation" in p;
    }
  };
  return p([h()], w.prototype, "popup", void 0), p([h()], w.prototype, "popupEnabled", void 0), w = p([l("esri.views.PopupView")], w), w;
};
class y {
  constructor(p) {
    this.layerView = p, this._resolver = a(), this.graphics = [];
  }
  get promise() {
    return this._resolver.promise;
  }
  resolve(p) {
    const {
      layerView: e,
      graphics: t,
      _resolver: o
    } = this;
    if (!e) return o.resolve(t), o.promise;
    let i;
    return e.fetchPopupFeaturesFromGraphics(t, p).catch(p => (i = p, null)).then(p => {
      p ? o.resolve(p) : o.reject(i);
    }), o.promise;
  }
}
const P = 5e3;
export { w as PopupView };