/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { find as e } from "../../core/iteratorUtils.js";
import { clamp as t } from "../../core/mathUtils.js";
import { createScreenPoint as o } from "../../core/screenUtils.js";
import { areToolManipulatorsEditable as a } from "./interactiveToolUtils.js";
import { createScreenPointFromEvent as r } from "../support/screenUtils.js";
class i {
  constructor() {
    this._pointerLocations = new Map(), this._hoveredManipulators = new Map(), this._grabbedManipulators = new Map(), this._draggedManipulators = new Map(), this._stopDrag = !1, this._externallyDragging = !1, this._revertToNullActiveTool = !1, this._cursor = null;
  }
  get cursor() {
    return this._cursor;
  }
  hasFocusedManipulators() {
    return this._grabbedManipulators.size > 0 || this._draggedManipulators.size > 0;
  }
  handleInputEvent(e, o) {
    const a = () => e.stopPropagation();
    switch (e.type) {
      case "pointer-move":
        l(e.pointerType) && this._pointerLocations.set(e.pointerId, {
          x: e.x,
          y: e.y,
          pointerType: e.pointerType
        });
        break;
      case "drag":
        this._grabbedManipulators.size > 0 ? this._stopDrag = !0 : "start" === e.action ? this._externallyDragging = !0 : "end" === e.action && (this._externallyDragging = !1), this._stopDrag && (a(), "end" === e.action && (this._stopDrag = !1));
        break;
      case "pointer-down":
        {
          if (!p(e)) break;
          const t = r(e),
            i = s(t, e.pointerType, o.forEachTool);
          if (null == i) break;
          const n = i.manipulator,
            l = i.tool;
          null == n || null == l || !n.interactive || n.grabbable && n.grabbableForEvent(e) || !n.grabbing || n.dragging || this._releaseManipulatorBeforeDragging(n, e, o), null != n && null != l && n.interactive && n.grabbable && n.grabbableForEvent(e) && !n.grabbing && (this._grabbedManipulators.set(e.pointerId, {
            manipulator: n,
            tool: l,
            start: t,
            pointerType: e.pointerType
          }), 1 === this._grabbedManipulators.size && null == o.activeTool && (this._revertToNullActiveTool = !0, o.setActiveTool(i.tool)), n.grabbing = !0, n.events.emit("grab-changed", {
            action: "start",
            pointerType: e.pointerType,
            screenPoint: t
          }), a());
          break;
        }
      case "pointer-up":
        this._draggedManipulators.has(e.pointerId) || this._handlePointerEnd(e, o);
        break;
      case "pointer-drag":
        {
          if (!p(e)) break;
          const i = this._grabbedManipulators.get(e.pointerId),
            n = i?.manipulator,
            s = i?.tool;
          if (null == n || null == s) break;
          const l = r(e);
          l.x = t(l.x, 0, o.view.width), l.y = t(l.y, 0, o.view.height);
          const u = i.start,
            c = this._draggedManipulators.get(e.pointerId);
          switch (e.action) {
            case "start":
            case "update":
              "update" !== e.action && 1 !== this._grabbedManipulators.size || (n.dragging = !0, c ? n.events.emit("drag", {
                action: "update",
                start: u,
                screenPoint: l
              }) : n.events.emit("drag", {
                action: "start",
                start: u,
                screenPoint: l,
                pointerType: e.pointerType
              }), this._draggedManipulators.set(e.pointerId, {
                tool: s,
                manipulator: n,
                start: u
              }));
              break;
            case "end":
              n.dragging = !1, c && n.events.emit("drag", {
                action: "end",
                start: u,
                screenPoint: l
              }), this._draggedManipulators.delete(e.pointerId), this._handlePointerEnd(e, o);
          }
          a();
          break;
        }
      case "immediate-click":
        {
          const t = r(e),
            i = s(t, e.pointerType, o.forEachTool);
          if (u(e) || o.forEachTool(e => {
            if ((null == i || i.tool !== e || e.automaticManipulatorSelection) && e.manipulators) {
              let t = !1;
              e.manipulators.forEach(({
                manipulator: e
              }) => {
                e.selected && (e.selected = !1, t = !0);
              }), t && e.onManipulatorSelectionChanged && e.onManipulatorSelectionChanged();
            }
          }), null == i) break;
          const {
            manipulator: n,
            tool: l
          } = i;
          if (!n.interactive) break;
          n.selectable && l.automaticManipulatorSelection && (n.selected = !n.selected, l.onManipulatorSelectionChanged && l.onManipulatorSelectionChanged());
          const p = e.native.shiftKey;
          n.events.emit("immediate-click", {
            screenPoint: t,
            button: e.button,
            pointerType: e.pointerType,
            shiftKey: p,
            stopPropagation: a
          }), c(n, a);
          break;
        }
      case "click":
        {
          const t = r(e),
            i = s(t, e.pointerType, o.forEachTool),
            n = i?.manipulator;
          if (null == n || !n.interactive) break;
          const l = e.native.shiftKey;
          n.events.emit(e.type, {
            screenPoint: t,
            button: e.button,
            pointerType: e.pointerType,
            shiftKey: l
          }), a();
          break;
        }
      case "double-click":
        {
          const t = r(e),
            i = s(t, e.pointerType, o.forEachTool),
            n = null != i ? i.manipulator : null;
          if (null == n || !n.interactive) break;
          const l = e.native.shiftKey;
          n.events.emit("double-click", {
            screenPoint: t,
            button: e.button,
            pointerType: e.pointerType,
            shiftKey: l,
            stopPropagation: a
          }), c(n, a);
          break;
        }
      case "immediate-double-click":
        {
          const t = r(e),
            i = s(t, e.pointerType, o.forEachTool),
            n = null != i ? i.manipulator : null;
          if (null == n || !n.interactive) break;
          const l = e.native.shiftKey;
          n.events.emit("immediate-double-click", {
            screenPoint: t,
            button: e.button,
            pointerType: e.pointerType,
            shiftKey: l,
            stopPropagation: a
          }), "mouse" === e.pointerType && c(n, a);
          break;
        }
    }
    this._onFocusChange(o.forEachTool);
  }
  _releaseManipulatorBeforeDragging(e, t, o) {
    e.grabbing = !1, e.events.emit("grab-changed", {
      action: "end",
      pointerType: t.pointerType,
      screenPoint: r(t)
    }), this._grabbedManipulators.forEach(({
      manipulator: t
    }, o) => {
      t === e && this._grabbedManipulators.delete(o);
    }), this._afterManipulatorRelease(o.setActiveTool);
  }
  _handlePointerEnd(e, t) {
    const o = this._grabbedManipulators.get(e.pointerId)?.manipulator;
    null != o && o.grabbing && (o.grabbing = !1, o.events.emit("grab-changed", {
      action: "end",
      pointerType: e.pointerType,
      screenPoint: r(e)
    }), this._grabbedManipulators.delete(e.pointerId), this._afterManipulatorRelease(t.setActiveTool));
  }
  _onFocusChange(e) {
    this._updateCursor(), this._updateFocusedManipulatorTools(e);
  }
  _updateCursor() {
    this._grabbedManipulators.size > 0 ? this._cursor = n(this._grabbedManipulators) || "grabbing" : this._hoveredManipulators.size > 0 ? this._cursor = n(this._hoveredManipulators) || "pointer" : this._cursor = null;
  }
  _updateFocusedManipulatorTools(t) {
    const o = new Set(),
      a = new Set();
    this._grabbedManipulators.forEach(({
      tool: e
    }) => {
      o.add(e);
    }), this._hoveredManipulators.forEach(({
      tool: e
    }) => {
      a.add(e);
    }), t(t => {
      t.hasGrabbedManipulators = o.has(t), t.hasHoveredManipulators = a.has(t);
      const r = this._grabbedManipulators.values(),
        i = e(r, ({
          tool: e
        }) => e === t);
      t.firstGrabbedManipulator = null != i ? i.manipulator : null;
    });
  }
  clearPointers(e, {
    forEachTool: t,
    setActiveTool: o
  }, a = !0, r) {
    const i = (t, o) => t === e && (null == r || r === o);
    this._grabbedManipulators.forEach(({
      tool: e,
      manipulator: t,
      pointerType: o
    }, a) => {
      i(e, t) && (this._grabbedManipulators.delete(a), t.grabbing = !1, t.events.emit("grab-changed", {
        action: "end",
        screenPoint: null,
        pointerType: o
      }));
    }), this._draggedManipulators.forEach(({
      tool: e,
      manipulator: t
    }, o) => {
      i(e, t) && (this._draggedManipulators.delete(o), t.dragging = !1, t.events.emit("drag", {
        action: "cancel"
      }));
    }), a && this._hoveredManipulators.forEach(({
      tool: e,
      manipulator: t
    }, o) => {
      i(e, t) && (this._hoveredManipulators.delete(o), t.hovering = !1);
    }), this._afterManipulatorRelease(o), this._onFocusChange(t);
  }
  updateHoveredStateFromKnownPointers(e) {
    this._pointerLocations.forEach((t, a) => {
      this._updateHoveredStateForPointerAtScreenPosition(o(t.x, t.y), a, t.pointerType, e);
    });
  }
  handleHoverEvent(e, t) {
    const o = e.type;
    "pointer-up" !== o && "immediate-click" !== o && "pointer-move" !== o || !l(e.pointerType) || ("pointer-up" !== o && this._externallyDragging ? this._clearHoveredManipulatorStates(e.pointerId) : this._updateHoveredStateForPointerAtScreenPosition(r(e), e.pointerId, e.pointerType, t));
  }
  _updateHoveredStateForPointerAtScreenPosition(e, t, o, a) {
    let r = s(e, o, a);
    const i = this._hoveredManipulators.get(t)?.manipulator;
    null == r || r.manipulator.interactive || (r = null), null != r && i === r.manipulator || (null != i && (i.hovering = !1), null != r ? (r.manipulator.hovering = !0, this._hoveredManipulators.set(t, r)) : this._hoveredManipulators.delete(t), this._onFocusChange(a));
  }
  _afterManipulatorRelease(e) {
    0 === this._grabbedManipulators.size && this._revertToNullActiveTool && (e(null), this._revertToNullActiveTool = !1);
  }
  _clearHoveredManipulatorStates(e) {
    this._hoveredManipulators.forEach(({
      manipulator: t
    }, o) => {
      e === o && (this._hoveredManipulators.delete(e), t.hovering = !1);
    });
  }
}
function n(e) {
  for (const {
    manipulator: t
  } of e.values()) if (null != t && t.interactive) {
    if (t.grabbing && t.grabCursor) return t.grabCursor;
    if (t.cursor) return t.cursor;
  }
  return null;
}
function s(e, t, o) {
  let r = null;
  return o(o => {
    if (null == o.manipulators || !a(o)) return !1;
    const i = o.manipulators.intersect(e, t);
    return null != i && (r = {
      tool: o,
      manipulator: i
    }, !0);
  }), r;
}
function l(e) {
  return "mouse" === e;
}
function p(e) {
  return "mouse" !== e.pointerType || 0 === e.button;
}
function u(e) {
  return !!e.native.shiftKey;
}
function c(e, t) {
  e?.consumesClicks && t();
}
export { i as ToolViewManagerManipulatorState };