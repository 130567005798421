/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as t } from "../../chunks/tslib.es6.js";
import o from "../../core/Accessor.js";
import { watch as e, initial as i } from "../../core/reactiveUtils.js";
import { property as r } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import "../../core/RandomLCG.js";
import { subclass as s } from "../../core/accessorSupport/decorators/subclass.js";
import { getGoToDuration as a } from "../../views/2d/navigation/duration.js";
import { canShowNorth as n } from "./utils.js";
import { GoToMixin as d } from "../support/GoTo.js";
let p = class extends d(o) {
  constructor(t) {
    super(t), this.orientation = {
      x: 0,
      y: 0,
      z: 0
    }, this.view = null, this._updateForCamera = this._updateForCamera.bind(this), this._updateForRotation = this._updateForRotation.bind(this), this._updateRotationWatcher = this._updateRotationWatcher.bind(this);
  }
  initialize() {
    this.addHandles(e(() => this.view, this._updateRotationWatcher, i));
  }
  destroy() {
    this.view = null;
  }
  get canShowNorth() {
    return n(this.view);
  }
  get state() {
    return !this.view?.ready || "2d" === this.view.type && !this.view.constraints.rotationEnabled ? "disabled" : this.canShowNorth ? "compass" : "rotation";
  }
  reset() {
    this.view?.ready && ("2d" === this.view?.type ? this.callGoTo({
      target: {
        rotation: 0
      },
      options: {
        animationMode: "always",
        duration: a()
      }
    }) : this.callGoTo({
      target: {
        heading: 0
      }
    }));
  }
  _updateForRotation(t) {
    null != t && this._set("orientation", {
      z: t
    });
  }
  _updateForCamera(t) {
    if (!t) return;
    const o = -t.heading;
    this._set("orientation", {
      x: 0,
      y: 0,
      z: o
    });
  }
  _updateRotationWatcher(t) {
    this.removeAllHandles(), t && this.addHandles("2d" === t.type ? e(() => t?.rotation, this._updateForRotation, i) : e(() => t?.camera, this._updateForCamera, i));
  }
};
t([r({
  readOnly: !0
})], p.prototype, "canShowNorth", null), t([r({
  readOnly: !0
})], p.prototype, "orientation", void 0), t([r({
  readOnly: !0
})], p.prototype, "state", null), t([r()], p.prototype, "view", void 0), p = t([s("esri.widgets.Compass.CompassViewModel")], p);
const h = p;
export { h as default };