/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as e } from "../chunks/tslib.es6.js";
import "../geometry.js";
import t from "../Viewpoint.js";
import i from "../core/Collection.js";
import r from "../core/CollectionFlattener.js";
import s from "../core/Error.js";
import has from "../core/has.js";
import a from "../core/Logger.js";
import { destroyMaybe as n } from "../core/maybe.js";
import { watch as o, sync as l, syncAndInitial as p } from "../core/reactiveUtils.js";
import { initialize as h } from "../core/workers/workers.js";
import { property as g } from "../core/accessorSupport/decorators/property.js";
import { cast as d } from "../core/accessorSupport/decorators/cast.js";
import "../core/RandomLCG.js";
import { subclass as u } from "../core/accessorSupport/decorators/subclass.js";
import { ensureClass as c } from "../core/accessorSupport/ensureType.js";
import { isLoadedOrLoadFor as m } from "../geometry/projection.js";
import y from "../layers/support/TileInfo.js";
import { BreakpointsOwner as f } from "./BreakpointsOwner.js";
import { DOMContainer as w } from "./DOMContainer.js";
import { PopupView as V } from "./PopupView.js";
import M from "./View.js";
import v from "./2d/AnimationManager.js";
import b from "./2d/FrameTask.js";
import { GoToManager as _ } from "./2d/GoToManager.js";
import { layerView2DImporter as j } from "./2d/layerViewModuleImportUtils.js";
import S from "./2d/MapViewConstraints.js";
import T from "./2d/tiling/TileInfoView.js";
import "./2d/tiling/TileKey.js";
import "./2d/tiling/TileQueue.js";
import "./2d/tiling/TileStrategy.js";
import { extentToScale as O } from "./2d/viewpointUtils.js";
import { ViewStateManager as L } from "./2d/ViewStateManager.js";
import R from "./2d/input/MapViewInputManager.js";
import k from "./2d/layers/features/support/TileStore.js";
import { createOrReuseHighlightGradient as C } from "./2d/layers/support/util.js";
import { clipContainsPoint as x } from "./2d/support/clippingUtils.js";
import { HighlightGroup as D } from "./2d/support/HighlightGroup.js";
import { createInitialHighlightGroups as E, getDefaultHighlightOptions as G, setDefaultHighlightOptions as P } from "./2d/support/highlightGroupUtils.js";
import z from "./2d/support/HighlightOptions.js";
import { hitTest as A } from "./2d/support/hitTestUtils.js";
import { takeRawScreenshot as U, takeScreenshot as q } from "./2d/support/screenshotUtils.js";
import { StationaryManager as I } from "./2d/support/StationaryManager.js";
import { Timeline as F } from "./2d/support/Timeline.js";
import { isSupportedScreenPointEvent as N, createScreenPointFromSupportedEvent as W } from "./support/screenUtils.js";
import { check as $ } from "./support/WebGLRequirements.js";
import H from "./ui/DefaultUI.js";
import B from "./ui/2d/DefaultUI2D.js";
import { isWebMap as Z } from "../webmap/utils.js";
import J from "../webmap/background/ColorBackground.js";
import K from "../geometry/Polygon.js";
let Q, X, Y, ee, te, ie, re;
async function se() {
  const [, {
    GraphicsView2D: e,
    GraphicContainer: t,
    LabelManager: i,
    MapViewNavigation: r,
    MagnifierView2D: s,
    GridView2D: a,
    Stage: n
  }] = await Promise.all([import("./2d/webglDeps.js"), import("./2d/mapViewDeps.js")]);
  X = e, Y = t, ee = i, te = r, ie = s, re = a, Q = n;
}
let ae = class extends f(V(w(M))) {
  constructor(e) {
    super(e), this._magnifierView = null, this._gridView = null, this.stage = null, this._resolveWhenReady = [], this.rootLayerViews = new r({
      getCollections: () => [this.basemapView?.baseLayerViews, this.layerViews, this.basemapView?.referenceLayerViews],
      getChildrenFunction: () => null
    }), this.featuresTilingScheme = null, this.fullOpacity = 1, this.goToManager = new _({
      view: this
    }), this.graphicsView = null, this.stateManager = new L({
      constraints: new S({
        view: this
      })
    }), this.stationaryManager = new I(), this.labelManager = null, this.mapViewNavigation = null, this.renderingOptions = {
      samplingMode: "dynamic",
      edgeLabelsVisible: !0,
      labelsAnimationTime: 125,
      labelCollisionsEnabled: !0
    }, this.supersampleScreenshotsEnabled = !0, this.supportsGround = !1, this.frameTask = new b(this), this.floors = new i(), this.grid = null, this.highlights = E(), this.inputManager = new R({
      view: this
    }), this.map = null, this.spatialReferenceLocked = !1, this.timeline = new F(), this.type = "2d", this.view2dType = null, this.ui = new B(), this.test = {
      takeScreenshot: async e => U(this._getScreenshotView(e), e)
    }, this.padding = {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0
    }, this.addHandles([o(() => this.viewpoint, () => this.stationaryManager.flip(), l), this.on("resize", e => this.stateManager.resize(e.width, e.height))]), h();
  }
  destroy() {
    this.layerViewManager.clear(), this._set("preconditionsReady", !1), this.frameTask = n(this.frameTask), this.goToManager.destroy(), this.rootLayerViews.destroy(), this.inputManager.destroy(), this._set("inputManager", null);
  }
  get graphicsTileStore() {
    return new k(this.featuresTilingScheme);
  }
  get constraintsInfo() {
    const e = this.defaultsFromMap?.tileInfo,
      t = this.spatialReference;
    return {
      lods: e?.spatialReference?.equals(t) ? e.lods : null,
      spatialReference: t
    };
  }
  get state() {
    return this.stateManager.state;
  }
  get initialExtentRequired() {
    if (!this.stateManager) return !1;
    const {
      scale: e,
      constraints: t,
      center: i,
      viewpoint: r,
      extent: s
    } = this;
    let a = this.zoom;
    return !(this.map && "initialViewProperties" in this.map && this.map.initialViewProperties?.viewpoint) && !s && (t?.effectiveLODs || (a = -1), (!i || 0 === e && -1 === a) && (null == r?.targetGeometry || "extent" !== r.targetGeometry.type && !r.scale));
  }
  get defaultsFromMapSettings() {
    return {
      required: {
        extent: !1,
        heightModelInfo: !1,
        tileInfo: !0
      },
      requiresExtentInSpatialReference: this.spatialReferenceLocked
    };
  }
  get scheduler() {
    return this.frameTask.scheduler;
  }
  get typeSpecificPreconditionsReady() {
    const e = this._getDefaultViewpoint();
    if (!e) return !1;
    const t = e.targetGeometry,
      i = this.spatialReference;
    return m(t.spatialReference, i);
  }
  set animation(e) {
    const t = this._get("animation");
    if (e === t) return;
    if (t && t.stop(), e !== this.animationManager.animation && this.animationManager.stop(), !e || e.isFulfilled()) return void this._set("animation", null);
    this._set("animation", e);
    const i = () => {
      this.destroyed || e === this._get("animation") && (this._set("animation", null), this.frameTask?.requestFrame());
    };
    e.when(i, i);
  }
  get background() {
    return Z(this.map) ? this.map.initialViewProperties.background : null;
  }
  set background(e) {
    this._override("background", e);
  }
  get center() {
    return this.stateManager?.center ?? null;
  }
  set center(e) {
    this.stateManager.center = e;
  }
  get constraints() {
    return this.stateManager?.constraints;
  }
  set constraints(e) {
    e.view = this;
    const t = this.stateManager.constraints;
    this.stateManager.constraints = e, t?.destroy();
  }
  get extent() {
    return this.stateManager?.extent ?? null;
  }
  set extent(e) {
    this.stateManager.extent = e;
  }
  get highlightOptions() {
    return G(this);
  }
  set highlightOptions(e) {
    P(this, e);
  }
  get padding() {
    return this.stateManager?.padding;
  }
  set padding(e) {
    this.stateManager && (this.stateManager.padding = e);
  }
  get rendering() {
    return this.stage?.renderRequested ?? !1;
  }
  get resizeAlign() {
    return this.stateManager.resizeAlign;
  }
  set resizeAlign(e) {
    this.stateManager.resizeAlign = e;
  }
  get resolution() {
    return this.stateManager.resolution ?? 0;
  }
  get rotation() {
    return this.stateManager.rotation ?? 0;
  }
  set rotation(e) {
    const {
      rotationEnabled: t
    } = this.constraints;
    this.constraints.rotationEnabled = !0, this.stateManager.rotation = e, this.constraints.rotationEnabled = t;
  }
  get scale() {
    return this.stateManager?.scale ?? 0;
  }
  set scale(e) {
    this.stateManager && (this.stateManager.scale = e);
  }
  get stationary() {
    return !this.animation && !this.navigating && !this.resizing && this.stationaryManager.stationary;
  }
  get tileInfo() {
    return this.featuresTilingScheme?.tileInfo;
  }
  get updating() {
    const e = !(!this.magnifier.visible || null === this.magnifier.position || !this._magnifierView?.updatingHandles.updating),
      t = !this.destroyed && (!this.layerViewManager || !this.labelManager || !this.graphicsView || !0 === this.layerViewManager.updating || !0 === this.labelManager.updating || !0 === this.graphicsView.updating || this.allLayerViews.some(e => !e.destroyed && !("layerViews" in e) && !0 === e.updating) || e);
    if (has("esri-2d-log-updating")) {
      const i = this.allLayerViews.reduce((e, t) => ({
        ...e,
        [`${t.layer.id}(${t.layer.type})`]: !t.destroyed && !("layerViews" in t) && t.updating
      }), {});
      console.log(`Updating MapView: ${t}\n-> Null LayerViewManager: ${!this.layerViewManager}\n-> Null LabelManager: ${!this.labelManager}\n-> Null GraphicsView: ${!this.graphicsView}\n-> layerViewManager.updating: ${this.layerViewManager?.updating}\n-> labelManager.updating: ${this.labelManager?.updating}\n-> graphicsView.updating: ${this.graphicsView?.updating}\n-> allLayerViews: ${JSON.stringify(i)}\n-> updatingMagnifier: ${e}\n`);
    }
    return t;
  }
  get viewpoint() {
    return this.stateManager.viewpoint ?? null;
  }
  set viewpoint(e) {
    this.stateManager.viewpoint = e, this.frameTask.requestFrame();
  }
  get visibleArea() {
    const e = this.stateManager.visibleArea;
    return e ? new K({
      rings: [e.map(e => [e[0], e[1]])],
      spatialReference: this.spatialReference
    }) : e;
  }
  get zoom() {
    return this.stateManager.zoom ?? -1;
  }
  set zoom(e) {
    this.stateManager.zoom = e;
  }
  get navigating() {
    return this.mapViewNavigation?.interacting ?? !1;
  }
  goTo(e, t) {
    return this.goToManager.goTo(e, t);
  }
  async hitTest(e, t) {
    return A(this, e, t);
  }
  async takeScreenshot(e) {
    return q(this._getScreenshotView(e), e);
  }
  toMap(e) {
    if (!this.ready) return null;
    const t = N(e) ? W(this, e) : e;
    return this.stateManager.toMap(t);
  }
  toScreen(e, t) {
    return this.stateManager.toScreen(e, t);
  }
  on(e, t, i, r) {
    const s = this.inputManager && this.viewEvents.on(e, t, i, r);
    return s || super.on(e, t);
  }
  hasEventListener(e) {
    return super.hasEventListener(e) || this.viewEvents.hasHandler(e);
  }
  whenLayerView(e) {
    return super.whenLayerView(e);
  }
  graphicChanged(e) {
    if (this.graphicsView) {
      this.graphicsView.graphicUpdateHandler(e);
    }
  }
  whenReady() {
    return new Promise(e => {
      this.ready ? e(this) : this._resolveWhenReady.push(e);
    });
  }
  forceDOMReadyCycle() {
    this.forceReadyCycle();
  }
  getDefaultSpatialReference() {
    return this.map && "initialViewProperties" in this.map && this.map.initialViewProperties.spatialReference || this.defaultsFromMap?.spatialReference || null;
  }
  getDefaultTimeZone() {
    return Z(this.map) ? this.map.initialViewProperties.timeZone : null;
  }
  getDefaultTimeExtent() {
    return Z(this.map) ? this.map.initialViewProperties.timeExtent : null;
  }
  hasLayerViewModule(e) {
    return j.hasLayerViewModule(e);
  }
  importLayerView(e) {
    return j.importLayerView(e);
  }
  pixelSizeAt() {
    return this.ready ? this.resolution : (a.getLogger(this).error("#pixelSizeAt()", "Map view cannot be used before it is ready"), null);
  }
  async popupHitTest(e) {
    const t = this.toMap(e),
      i = await this.hitTest(e),
      r = this.allLayerViews.toArray().filter(i => i.clips.every(i => x(this, i, e, t))).reverse(),
      s = new globalThis.Map(r.map(e => [e.layer.uid, e])),
      a = [];
    let n = 0,
      o = 0;
    for (; n < i.results.length || o < r.length;) {
      const e = i.results.at(n);
      if (e && "graphic" !== e.type) {
        ++n;
        continue;
      }
      const l = s.get((e?.layer ?? e?.graphic.layer)?.uid);
      if ((!e || l) && o < r.length && r.at(o) !== l) {
        const e = r.at(o);
        "fetchPopupFeaturesAtLocation" in e && a.push({
          mapPoint: t,
          layerView: e
        }), ++o;
      } else e && (a.push({
        graphic: e.graphic,
        layerView: l
      }), ++n);
    }
    return {
      hits: a,
      location: t
    };
  }
  requestUpdate() {
    this.ready && this.frameTask.requestUpdate();
  }
  async validate() {
    let e = $(this.type);
    if (has("safari") && has("safari") < 9 && (e = new s("mapview:browser-not-supported", "This browser is not supported by MapView (Safari < 9)", {
      type: "safari",
      requiredVersion: 9,
      detectedVersion: has("safari")
    })), null != e) throw a.getLogger(this).warn("#validate()", e.message), e;
  }
  loadAsyncDependencies() {
    return se();
  }
  _getDefaultViewpoint() {
    const {
      constraints: e,
      initialExtent: i,
      map: r,
      padding: s,
      size: a
    } = this;
    if (!e) return null;
    const n = r && "initialViewProperties" in r ? r.initialViewProperties : void 0,
      o = this.stateManager.getUserStartupOptions(this.size),
      l = n?.viewpoint,
      p = l?.targetGeometry?.extent ?? i,
      h = p?.center,
      g = l?.rotation ?? 0,
      d = l?.scale || p && O(p, [a[0] - s.left - s.right, a[1] - s.top - s.bottom]),
      u = o.center ?? h,
      c = o.rotation ?? g,
      m = o.scale ?? d;
    return u && m ? new t({
      targetGeometry: u,
      scale: m,
      rotation: c
    }) : null;
  }
  _startup() {
    this.timeline.begin("MapView Startup");
    const e = this._getDefaultViewpoint();
    this.stateManager.startup(e, this.size, this.spatialReference, this.defaultsFromMap.extent?.center), this.graphics.owner = this;
    const t = new Q(this.surface, {
      canvas: this.renderCanvas,
      contextOptions: {
        disabledExtensions: this.deactivatedWebGLExtensions,
        debugWebGLExtensions: this.debugWebGLExtensions
      },
      renderingOptions: this.renderingOptions,
      timeline: this.timeline
    });
    this.stage = t, this._magnifierView = new ie(), this._magnifierView.magnifier = this.magnifier, this._gridView = new re();
    const i = new ee({
      view: this
    });
    this._set("labelManager", i);
    const r = new v({
      view: this
    });
    this._set("animationManager", r);
    const s = new te({
      view: this,
      animationManager: r
    });
    this._set("mapViewNavigation", s), this._setupSpatialReferenceDependentProperties(), this.addHandles([this.rootLayerViews.on("change", () => this._updateStageChildren()), t.on("webgl-error", e => this.fatalError = e.error), o(() => this.stationary, e => t.stationary = e, p), o(() => this.background, e => {
      t.backgroundColor = e?.color, this._magnifierView.backgroundColor = e?.color;
    }, p), o(() => this.magnifier, e => this._magnifierView.magnifier = e, p), o(() => this.grid, e => this._gridView.grid = e, p), o(() => this.renderingOptions, e => t.renderingOptions = e, p), o(() => this.highlights.items.map(e => ({
      name: e.name,
      options: {
        fillColor: e.options.color,
        haloColor: e.options.haloColor,
        fillOpacity: e.options.fillOpacity,
        haloOpacity: e.options.haloOpacity,
        haloWidth: e.options.haloWidth,
        haloBlur: e.options.haloBlur
      }
    })), () => {
      t.highlightGradient = C(t.highlightGradient, this.highlights.items);
    }, p), o(() => this.state.id, () => t.state = this.state, p)], "map-view"), this._updateStageChildren();
    const a = this._resolveWhenReady;
    this._resolveWhenReady = [], a.forEach(e => e(this)), this.timeline.end("MapView Startup"), this.frameTask.start(), this._set("ready", !0);
  }
  _teardown() {
    this._destroySpatialReferenceDependentProperties(), this.removeHandles("map-view"), this.mapViewNavigation.destroy(), this._set("mapViewNavigation", null), this.animation = null, this.animationManager.destroy(), this._set("animationManager", null), this.layerViewManager.clear(), this.labelManager.destroy(), this._magnifierView.destroy(), this._gridView.destroy(), this.stage.destroy(), this.stage = null, this._set("graphicsView", null), this._magnifierView = null, this._gridView = null, this._set("labelManager", null), this._set("mapViewNavigation", null), this.graphics.owner = null, this.frameTask.stop(), this.stationaryManager.clear(), this._set("ready", !1), this.stateManager.teardown();
  }
  _updateStageChildren() {
    this.stage.removeAllChildren(), this.rootLayerViews.forEach(e => {
      this.stage.addChild(e.container);
    });
    const e = this.graphicsView;
    this.stage.addChild(e.container), this.stage.addChild(this._magnifierView), this.stage.addChild(this._gridView);
  }
  _setupSpatialReferenceDependentProperties() {
    const e = new T(y.create({
      spatialReference: this.spatialReference,
      size: 512,
      numLODs: 36
    }));
    this._set("featuresTilingScheme", e);
    const t = new X({
      view: this,
      graphics: this.graphics,
      requestUpdateCallback: () => this.requestUpdate(),
      container: new Y(e)
    });
    this._set("graphicsView", t);
  }
  _destroySpatialReferenceDependentProperties() {
    const e = this.graphicsView;
    this._set("graphicsView", null), e.destroy(), this._set("featuresTilingScheme", null);
  }
  _getScreenshotView(e) {
    const {
      allLayerViews: t,
      padding: i,
      size: r,
      stage: s
    } = this;
    return {
      allLayerViews: t,
      backgroundColor: e?.ignoreBackground ? null : this.background?.color,
      padding: i,
      size: r,
      stage: s
    };
  }
  _spatialReferenceChanged(e) {
    if (this.ready) {
      this.frameTask.stop();
      for (const e of this.allLayerViews) e.processDetach();
      this._destroySpatialReferenceDependentProperties(), this.stateManager.changeSpatialReference(e), this.stage.state = this.state, this._setupSpatialReferenceDependentProperties();
      for (const e of this.allLayerViews) e.processAttach();
      this.frameTask.requestFrame(), this.frameTask.start(), this._updateStageChildren();
    }
  }
};
ae.type = "2d", e([g({
  readOnly: !0
})], ae.prototype, "animationManager", void 0), e([g({
  constructOnly: !0
})], ae.prototype, "deactivatedWebGLExtensions", void 0), e([g({
  constructOnly: !0
})], ae.prototype, "debugWebGLExtensions", void 0), e([g({
  readOnly: !0
})], ae.prototype, "featuresTilingScheme", void 0), e([g({
  readOnly: !0
})], ae.prototype, "fullOpacity", void 0), e([g()], ae.prototype, "goToManager", void 0), e([g({
  readOnly: !0
})], ae.prototype, "graphicsTileStore", null), e([g()], ae.prototype, "graphicsView", void 0), e([g()], ae.prototype, "stateManager", void 0), e([g()], ae.prototype, "constraintsInfo", null), e([g({
  readOnly: !0
})], ae.prototype, "state", null), e([g()], ae.prototype, "initialExtentRequired", null), e([g()], ae.prototype, "labelManager", void 0), e([g({
  readOnly: !0
})], ae.prototype, "mapViewNavigation", void 0), e([g({
  constructOnly: !0
})], ae.prototype, "renderCanvas", void 0), e([g()], ae.prototype, "renderingOptions", void 0), e([g({
  constructOnly: !0
})], ae.prototype, "supersampleScreenshotsEnabled", void 0), e([g({
  readOnly: !0
})], ae.prototype, "supportsGround", void 0), e([g()], ae.prototype, "defaultsFromMapSettings", null), e([g({
  readOnly: !0
})], ae.prototype, "typeSpecificPreconditionsReady", null), e([g()], ae.prototype, "animation", null), e([g({
  type: J
})], ae.prototype, "background", null), e([g()], ae.prototype, "center", null), e([g({
  type: S
})], ae.prototype, "constraints", null), e([g()], ae.prototype, "extent", null), e([g()], ae.prototype, "floors", void 0), e([g()], ae.prototype, "grid", void 0), e([g({
  type: z
})], ae.prototype, "highlightOptions", null), e([g({
  type: i.ofType(D)
})], ae.prototype, "highlights", void 0), e([g({
  readOnly: !0
})], ae.prototype, "inputManager", void 0), e([g()], ae.prototype, "map", void 0), e([g()], ae.prototype, "padding", null), e([g({
  readOnly: !0
})], ae.prototype, "rendering", null), e([g()], ae.prototype, "resizeAlign", null), e([g({
  readOnly: !0
})], ae.prototype, "resolution", null), e([g()], ae.prototype, "rotation", null), e([g()], ae.prototype, "scale", null), e([g({
  constructOnly: !0
})], ae.prototype, "spatialReferenceLocked", void 0), e([g()], ae.prototype, "stationary", null), e([g({
  readOnly: !0
})], ae.prototype, "tileInfo", null), e([g({
  type: F,
  readOnly: !0
})], ae.prototype, "timeline", void 0), e([g({
  readOnly: !0
})], ae.prototype, "type", void 0), e([g({
  readOnly: !0
})], ae.prototype, "updating", null), e([g({
  readOnly: !0
})], ae.prototype, "view2dType", void 0), e([g()], ae.prototype, "viewpoint", null), e([g({
  readOnly: !0
})], ae.prototype, "visibleArea", null), e([g()], ae.prototype, "zoom", null), e([g({
  readOnly: !0
})], ae.prototype, "navigating", null), e([g(), d(e => e instanceof H ? e : c(B, e))], ae.prototype, "ui", void 0), ae = e([u("esri.views.View2D")], ae);
const ne = ae;
export { ne as default };