/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as t } from "../../chunks/tslib.es6.js";
import o from "../../core/Accessor.js";
import { when as i } from "../../core/reactiveUtils.js";
import { property as e } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import "../../core/RandomLCG.js";
import { subclass as a } from "../../core/accessorSupport/decorators/subclass.js";
let s = class extends o {
  constructor(t) {
    super(t), this._navigationMode = "pan", this.view = null;
  }
  initialize() {
    this.addHandles(i(() => this.view?.inputManager, () => this._setNavigationMode()));
  }
  destroy() {
    this.view = null;
  }
  get state() {
    return this.view?.ready && "3d" === this.view?.type ? "ready" : "disabled";
  }
  get navigationMode() {
    return this._navigationMode;
  }
  set navigationMode(t) {
    this._navigationMode = t, this._setNavigationMode();
  }
  toggle() {
    "disabled" !== this.state && (this.navigationMode = "pan" !== this.navigationMode ? "pan" : "rotate");
  }
  _setNavigationMode() {
    const t = this.view?.inputManager;
    t && (t.primaryDragAction = "pan" === this._navigationMode ? "pan" : "rotate");
  }
};
t([e({
  readOnly: !0
})], s.prototype, "state", null), t([e()], s.prototype, "_navigationMode", void 0), t([e()], s.prototype, "view", void 0), s = t([a("esri.widgets.NavigationToggle.NavigationToggleViewModel")], s);
const r = s;
export { r as default };