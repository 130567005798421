/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { h as t } from "../../chunks/vec32.js";
function n(t) {
  let n = t * t;
  return t < 0 && (n *= -1), n;
}
function a(t) {
  return t.translation[0] = 0, t.translation[1] = 0, t.translation[2] = 0, t.heading = 0, t.tilt = 0, t;
}
function i(a, i, o) {
  const s = o,
    e = a.state,
    r = a.device,
    l = "forward-down" === i.tiltDirection ? 1 : -1,
    c = 1;
  return "standard" === r.deviceType ? (s.translation[0] = n(e.axes[0]), s.translation[1] = n(e.axes[1]), s.translation[2] = n(e.buttons[7]) - n(e.buttons[6]), s.heading = n(e.axes[2]), s.tilt = n(e.axes[3])) : "spacemouse" === r.deviceType && (s.translation[0] = 1.2 * n(e.axes[0]), s.translation[1] = 1.2 * n(e.axes[1]), s.translation[2] = 2 * -n(e.axes[2]), s.heading = 1.2 * n(e.axes[5]), s.tilt = 1.2 * n(e.axes[3])), s.tilt *= l, t(s.translation, s.translation, c), s;
}
function o(t, n) {
  const a = n;
  return a.translation[0] = t[1] - t[0], a.translation[1] = t[3] - t[2], a.translation[2] = t[4] - t[5], a.heading = t[7] - t[6], a.tilt = t[8] - t[9], a.zoom = t[10] - t[11], a;
}
function s(t) {
  return 0 === t.translation[0] && 0 === t.translation[1] && 0 === t.translation[2] && 0 === t.heading && 0 === t.tilt && 0 === t.zoom;
}
export { i as extractTransformation, o as extractTransformationKeyboard, s as isZeroTransformation, a as resetTransformation };