/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as e } from "../../chunks/tslib.es6.js";
import t from "../../core/Accessor.js";
import { createTask as n } from "../../core/asyncUtils.js";
import { abortMaybe as a } from "../../core/maybe.js";
import { isAborted as s, isAbortError as i } from "../../core/promiseUtils.js";
import { property as r } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import "../../core/RandomLCG.js";
import { subclass as l } from "../../core/accessorSupport/decorators/subclass.js";
import { deriveHeightModelInfoFromLayer as o, supportsHeightModelInfo as p } from "../../geometry/support/heightModelInfoUtils.js";
import { ViewingMode as u } from "../ViewingMode.js";
import { projectWithEngineOrService as c } from "./projectionUtils.js";
let d = class extends t {
  constructor(e) {
    super(e), this.required = {
      extent: !1,
      heightModelInfo: !1,
      tileInfo: !1
    }, this.defaultSpatialReference = null, this.userSpatialReference = null, this.sourcePreloadCount = 10, this.priorityCollection = null, this.requiresExtentInSpatialReference = !0, this.suspended = !1, this._projectExtentTask = {
      task: null,
      input: null,
      output: null,
      spatialReference: null
    };
  }
  destroy() {
    this._projectExtentTask.task && (this._projectExtentTask.task = a(this._projectExtentTask.task)), this._set("map", null);
  }
  get ready() {
    return !this._spatialReferenceTask.updating && !this._tileInfoTask.updating && !this._extentTask.updating;
  }
  get heightModelInfoReady() {
    return !this._heightModelInfoTask.updating;
  }
  get spatialReference() {
    return this.userSpatialReference ?? this._spatialReferenceTask.spatialReference;
  }
  get extent() {
    return this._extentTask.extent;
  }
  get heightModelInfo() {
    return this._heightModelInfoTask.heightModelInfo;
  }
  get vcsWkid() {
    return this._heightModelInfoTask.vcsWkid;
  }
  get latestVcsWkid() {
    return this._heightModelInfoTask.latestVcsWkid;
  }
  get viewingMode() {
    return null == this.userSpatialReference || this.userSpatialReference.equals(this._spatialReferenceTask.spatialReference) ? this._spatialReferenceTask.viewingMode : null;
  }
  get tileInfo() {
    return this._tileInfoTask.tileInfo;
  }
  get mapCollections() {
    const e = this.map?.(),
      t = [];
    return null != this.priorityCollection && t.push(this.priorityCollection), t.push({
      parent: e?.basemap,
      layers: e?.basemap?.baseLayers
    }, {
      layers: e?.layers
    }, {
      parent: e?.ground,
      layers: e?.ground?.layers
    }, {
      parent: e?.basemap,
      layers: e?.basemap?.referenceLayers
    }), t;
  }
  get _spatialReferenceTask() {
    if (this.suspended) return this._get("_spatialReferenceTask") ?? {
      updating: !1
    };
    let e;
    if (this._collectLayers(this.mapCollections, t => {
      const n = this._getSupportedSpatialReferences(t);
      if (n.length > 0) {
        const t = this._narrowDownSpatialReferenceCandidates(e, n);
        null != t && (e = t);
      }
      return 1 === e?.length;
    }) && 1 !== e?.length) return {
      updating: !0
    };
    const t = this._pickSpatialReferenceCandidate(e);
    return {
      spatialReference: t?.spatialReference ?? null,
      viewingMode: t?.viewingMode ?? null,
      updating: !1
    };
  }
  get _tileInfoTask() {
    if (!this.required.tileInfo) return this._get("_tileInfoTask") ?? {
      updating: !1
    };
    const e = this.map?.(),
      t = this.spatialReference;
    if (!t) return {
      updating: this._spatialReferenceTask.updating
    };
    let n;
    const a = this._collectLayers([{
      parent: e?.basemap,
      layers: e?.basemap?.baseLayers
    }, {
      layers: e?.layers
    }], e => !(!("tileInfo" in e) || !e.tileInfo?.spatialReference.equals(t)) && (n = e, !0), e => "tileInfo" in e);
    if (n) {
      return {
        tileInfo: n.tileInfo,
        updating: !1
      };
    }
    return {
      updating: a
    };
  }
  get _heightModelInfoTask() {
    if (!this.required.heightModelInfo || this.suspended && this._get("_heightModelInfoTask")?.heightModelInfo) return this._get("_heightModelInfoTask") ?? {
      updating: !1
    };
    let e = {};
    const t = this._collectLayers(this.mapCollections, t => {
      const n = o(t);
      return !!n && (e = {
        heightModelInfo: n,
        vcsWkid: t.spatialReference?.vcsWkid,
        latestVcsWkid: t.spatialReference?.latestVcsWkid
      }, !0);
    }, e => p(e));
    return {
      ...e,
      updating: t
    };
  }
  get _extentCandidatesTask() {
    if (this.suspended || !this.required.extent) return this._get("_extentCandidatesTask") ?? {
      updating: !1
    };
    if (!this.spatialReference) return {
      updating: this._spatialReferenceTask.updating
    };
    const e = [],
      t = this._collectLayers(this.mapCollections, t => {
        const n = "fullExtents" in t && t.fullExtents || (null != t.fullExtent ? [t.fullExtent] : []),
          a = this.requiresExtentInSpatialReference ? null : n[0],
          s = n.find(e => e.spatialReference.equals(this.spatialReference)) ?? a;
        if (s) return e.push({
          extent: s,
          layer: t
        }), !0;
        if (this._getSupportedSpatialReferences(t).length > 0) for (const i of n) e.push({
          extent: i,
          layer: t
        });
        return !1;
      });
    return {
      candidates: e,
      updating: t
    };
  }
  get _extentTask() {
    const {
      candidates: e,
      updating: t
    } = this._extentCandidatesTask;
    if (t) return {
      updating: t
    };
    if (null == e || 0 === e.length) return {
      updating: !1
    };
    if (!this.spatialReference) return {
      updating: this._spatialReferenceTask.updating
    };
    const i = this._pickExtentCandidate(e),
      r = this.spatialReference;
    return i.extent.equals(this._projectExtentTask.input) && r.equals(this._projectExtentTask.spatialReference) ? {
      extent: this._projectExtentTask.output,
      updating: null != this._projectExtentTask.task && !this._projectExtentTask.task.finished
    } : (null != this._projectExtentTask.task && (this._projectExtentTask.task = a(this._projectExtentTask.task)), this._projectExtentTask = {
      input: i.extent.clone(),
      output: null,
      spatialReference: r.clone(),
      task: n(async e => {
        try {
          const t = await c(i.extent, r, "portalItem" in i.layer ? i.layer.portalItem : void 0, e);
          this._projectExtentTask = {
            ...this._projectExtentTask,
            task: null,
            output: t
          };
        } catch (t) {
          if (s(e)) return;
          this._projectExtentTask = {
            ...this._projectExtentTask,
            task: null
          };
        }
      })
    }, {
      updating: !0
    });
  }
  _narrowDownSpatialReferenceCandidates(e, t) {
    if (null == e) return t;
    const n = new Array();
    for (const a of e) for (const e of t) {
      if (!a.spatialReference.equals(e.spatialReference)) continue;
      const t = h(a.viewingMode, e.viewingMode);
      if (!1 !== t) {
        n.push({
          spatialReference: a.spatialReference,
          viewingMode: t
        });
        break;
      }
    }
    return n.length > 0 ? n : null;
  }
  _pickSpatialReferenceCandidate(e) {
    const t = this.defaultSpatialReference;
    return null == e || e.length < 1 ? t ? {
      spatialReference: t,
      viewingMode: null
    } : null : (null != t && e.length > 1 && e.some(({
      spatialReference: e
    }) => e.equals(t)) && (e = e.filter(({
      spatialReference: e
    }) => e.equals(t))), e.length > 1 && e.some(({
      viewingMode: e
    }) => e !== u.Local) && (e = e.filter(({
      viewingMode: e
    }) => e !== u.Local)), e[0]);
  }
  _getSupportedSpatialReferences(e) {
    const t = "supportedSpatialReferences" in e && e.supportedSpatialReferences || (e.spatialReference ? [e.spatialReference] : []);
    if (0 === t.length) return [];
    const n = [];
    for (const a of t) {
      const t = this.getSpatialReferenceSupport(a, e);
      if (null != t) {
        const e = t.constraints ?? [{
          spatialReference: a,
          viewingMode: null
        }];
        for (const {
          spatialReference: t,
          viewingMode: a
        } of e) this.requiresExtentInSpatialReference && null != this.userSpatialReference && !t.equals(this.userSpatialReference) || n.push({
          spatialReference: t,
          viewingMode: a
        });
      }
    }
    return n;
  }
  _pickExtentCandidate(e) {
    const t = this.spatialReference;
    return e.find(({
      extent: e
    }) => t.equals(e.spatialReference)) || e[0];
  }
  _collectLayers(e, t, n = () => !0) {
    if ("loaded" !== this._loadMaybe(this.map?.())) return !0;
    const a = new f(n, t);
    for (const s of e) if (this._collectCollection(s, a), a.done || a.preloading === this.sourcePreloadCount) break;
    return a.updating;
  }
  _collectCollection(e, t) {
    if (e.layers) {
      switch (this._loadMaybe(e.parent)) {
        case "loading":
          return t.updating = !0, void ++t.preloading;
        case "failed":
          return;
      }
      for (const n of e.layers) if (t.layerFilter(n)) {
        switch (this._loadMaybe(n)) {
          case "failed":
            continue;
          case "loading":
            t.updating = !0, ++t.preloading;
            break;
          case "loaded":
            if (t.updating || (t.done = t.pushLayer(n)), t.done || t.preloading === this.sourcePreloadCount) break;
            "layers" in n && this._collectCollection({
              layers: n.layers
            }, t);
        }
        if (t.done || t.preloading === this.sourcePreloadCount) break;
      }
    }
  }
  _loadMaybe(e) {
    return e && "loadStatus" in e && null != e.loadStatus ? "not-loaded" === e.loadStatus ? (e.load().catch(e => {
      i(e);
    }), "loading") : e.loadStatus : "loaded";
  }
};
e([r()], d.prototype, "required", void 0), e([r({
  constructOnly: !0
})], d.prototype, "map", void 0), e([r({
  constructOnly: !0
})], d.prototype, "getSpatialReferenceSupport", void 0), e([r()], d.prototype, "defaultSpatialReference", void 0), e([r()], d.prototype, "userSpatialReference", void 0), e([r()], d.prototype, "sourcePreloadCount", void 0), e([r()], d.prototype, "priorityCollection", void 0), e([r()], d.prototype, "requiresExtentInSpatialReference", void 0), e([r()], d.prototype, "suspended", void 0), e([r({
  readOnly: !0
})], d.prototype, "ready", null), e([r({
  readOnly: !0
})], d.prototype, "heightModelInfoReady", null), e([r({
  readOnly: !0
})], d.prototype, "spatialReference", null), e([r({
  readOnly: !0
})], d.prototype, "extent", null), e([r({
  readOnly: !0
})], d.prototype, "heightModelInfo", null), e([r({
  readOnly: !0
})], d.prototype, "vcsWkid", null), e([r({
  readOnly: !0
})], d.prototype, "latestVcsWkid", null), e([r({
  readOnly: !0
})], d.prototype, "viewingMode", null), e([r({
  readOnly: !0
})], d.prototype, "tileInfo", null), e([r({
  readOnly: !0
})], d.prototype, "mapCollections", null), e([r({
  readOnly: !0
})], d.prototype, "_spatialReferenceTask", null), e([r({
  readOnly: !0
})], d.prototype, "_tileInfoTask", null), e([r({
  readOnly: !0
})], d.prototype, "_heightModelInfoTask", null), e([r({
  readOnly: !0
})], d.prototype, "_extentCandidatesTask", null), e([r()], d.prototype, "_extentTask", null), e([r()], d.prototype, "_projectExtentTask", void 0), d = e([l("esri.views.support.DefaultsFromMap")], d);
class f {
  constructor(e, t) {
    this.layerFilter = e, this.pushLayer = t, this.preloading = -1, this.updating = !1, this.done = !1;
  }
}
function h(e, t) {
  return null != e ? null != t ? e === t && e : e : t;
}
export { d as DefaultsFromMap };