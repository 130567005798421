/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as o } from "../../../chunks/tslib.es6.js";
import t from "../../../core/Accessor.js";
import { NumericIdentifiableMixin as r } from "../../../core/Identifiable.js";
import { property as e } from "../../../core/accessorSupport/decorators/property.js";
import "../../../core/has.js";
import "../../../core/Logger.js";
import "../../../core/RandomLCG.js";
import { subclass as s } from "../../../core/accessorSupport/decorators/subclass.js";
var a;
let n = a = class extends r(t) {
  constructor() {
    super(...arguments), this.enabled = !0, this.rotationEnabled = !0;
  }
  constrain(o, t) {
    return this.enabled && t ? (this.rotationEnabled || (o.rotation = t.rotation), o) : o;
  }
  clone() {
    return new a({
      enabled: this.enabled,
      rotationEnabled: this.rotationEnabled
    });
  }
};
o([e()], n.prototype, "enabled", void 0), o([e()], n.prototype, "rotationEnabled", void 0), n = a = o([s("esri.views.2d.constraints.RotationConstraint")], n);
const i = n;
export { i as default };