/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { isPromiseLike as t } from "../../core/promiseUtils.js";
import { watch as e } from "../../core/reactiveUtils.js";
import { addFrameTask as a } from "../../core/scheduling.js";
import s from "./PaddedViewState.js";
import { RenderState as i } from "../support/RenderState.js";
import { newScheduler as r } from "../support/Scheduler.js";
class d {
  constructor(e) {
    this.view = e, this._stationaryHandle = null, this._frameTaskHandle = null, this._updateParameters = null, this._updateRequested = !1, this._scheduler = r(), this.stationary = !0, this.prepare = () => {
      this._updateParameters && (this._updateParameters.state = this.view.state, this._updateParameters.stationary = this.view.stationary, this._updateParameters.pixelRatio = window.devicePixelRatio, this._updateParameters.renderingOptions = this.view.renderingOptions, this._updateParameters.targetState.copy(this.view.state), null == this.view.animation?.target || t(this.view.animation.target) || (this._updateParameters.targetState.viewpoint = this.view.animation.target));
    }, this.update = t => {
      if (this._updateRequested = !1, this.view?.destroyed) return;
      const {
        allLayerViews: e,
        graphicsView: a,
        labelManager: s,
        state: {
          id: r
        }
      } = this.view;
      e.forEach(this._updateLayerView, this), null != s && (s.lastUpdateId !== r && (s.viewChange(), s.lastUpdateId = r), s.updateRequested && s.processUpdate(this._updateParameters)), null != a && (a.lastUpdateId !== r && (a.viewChange(), a.lastUpdateId = r), a.updateRequested && a.processUpdate(this._updateParameters)), this.view.graphicsTileStore.setViewState(this._updateParameters.state), this.view.animation ? this._scheduler.state = i.ANIMATING : this.view.interacting ? this._scheduler.state = i.INTERACTING : this._scheduler.state = i.IDLE, this._scheduler.updateBudget(t) && this._scheduler.frame();
    };
  }
  destroy() {
    this.stop(), this._scheduler.destroy();
  }
  get scheduler() {
    return this._scheduler;
  }
  start() {
    if (this._frameTaskHandle) return;
    const t = this.view;
    this.stationary = t.stationary, this._updateParameters = {
      state: t.state,
      targetState: new s(),
      pixelRatio: window.devicePixelRatio,
      stationary: this.stationary,
      renderingOptions: t.renderingOptions
    }, this._stationaryHandle = e(() => t.stationary, t => {
      this.stationary = t, this.requestFrame();
    }), this._frameTaskHandle = a(this), this.requestUpdate();
  }
  stop() {
    this._frameTaskHandle && (this._updateRequested = !1, this._stationaryHandle?.remove(), this._frameTaskHandle.remove(), this._updateParameters = this._stationaryHandle = this._frameTaskHandle = null, this.stationary = !0);
  }
  requestUpdate() {
    this._updateRequested || (this._updateRequested = !0, this.requestFrame());
  }
  requestFrame() {
    this._frameTaskHandle && this._frameTaskHandle.resume();
  }
  _updateLayerView(t) {
    if (!t.attached) return void this.requestUpdate();
    const e = this.view.state,
      a = t.lastUpdateId;
    null != a && (this.stationary || t.moving) || (t.moving = !0), a !== e.id && t.viewChange(), this.stationary && t.moving && (t.moving = !1, t.moveEnd()), t.lastUpdateId = e.id, t.updateRequested && t.processUpdate(this._updateParameters), "layerViews" in t && t.layerViews?.forEach(this._updateLayerView, this);
  }
}
export { d as default };