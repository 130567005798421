/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
let e,
  t = null;
const n = new Map();
async function i(i) {
  null == t && (null == e && (e = import("../../../layers/Lyr3DWasmPerSceneView.js")), t = await e);
  const l = i.view;
  let r = n.get(l);
  r || (r = new t.default({
    view: l
  }), n.set(l, r));
  const a = !!l._stage.renderView.renderingContext.capabilities.compressedTextureS3TC;
  return await r.initializeWasm(a), r.add3DTilesLayerView(i);
}
function l(e) {
  return n.get(e);
}
function r(i) {
  const l = i.view,
    r = n.get(l);
  if (r) {
    r.remove3DTilesLayerView(i) < 1 && (n.delete(l), 0 === n.size && (e = null, t = null));
  }
}
export { i as addLayerViewToWasm, l as getLyr3DWasm, r as removeLayerViewFromWasm };