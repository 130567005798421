/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as r } from "../../../chunks/tslib.es6.js";
import t from "../../../core/Accessor.js";
import { property as o } from "../../../core/accessorSupport/decorators/property.js";
import "../../../core/has.js";
import "../../../core/Logger.js";
import "../../../core/RandomLCG.js";
import { subclass as s } from "../../../core/accessorSupport/decorators/subclass.js";
const e = 160;
let i = class extends t {
  constructor() {
    super(...arguments), this._timer = void 0;
  }
  get stationary() {
    return !this._timer;
  }
  flip() {
    this._timestamp = performance.now(), null == this._timer && (this._timer = setInterval(() => {
      performance.now() - this._timestamp >= e && this.clear();
    }, e));
  }
  clear() {
    this._timer && (clearInterval(this._timer), this._timer = void 0);
  }
};
r([o()], i.prototype, "_timer", void 0), r([o()], i.prototype, "stationary", null), i = r([s("esri.views.2d.support.StationaryManager")], i);
export { i as StationaryManager };