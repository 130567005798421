/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as o } from "../chunks/tslib.es6.js";
import { property as t } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/Logger.js";
import "../core/RandomLCG.js";
import { subclass as e } from "../core/accessorSupport/decorators/subclass.js";
import s from "./Widget.js";
import { loadCalciteComponents as i } from "./support/componentsUtils.js";
import { globalCss as r } from "./support/globalCss.js";
import "./support/widgetUtils.js";
import { messageBundle as l } from "./support/decorators/messageBundle.js";
import { tsx as n } from "./support/jsxFactory.js";
import a from "./Zoom/ZoomViewModel.js";
const m = {
  base: "esri-zoom",
  horizontalLayout: "esri-zoom--horizontal"
};
let c = class extends s {
  constructor(o, t) {
    super(o, t), this.messages = null, this.viewModel = new a(), this.zoomIn = () => this.viewModel.zoomIn(), this.zoomOut = () => this.viewModel.zoomOut();
  }
  loadDependencies() {
    return i({
      button: () => import("@esri/calcite-components/dist/components/calcite-button.js")
    });
  }
  get icon() {
    return "magnifying-glass-plus";
  }
  set icon(o) {
    this._overrideIfSome("icon", o);
  }
  get label() {
    return this.messages?.widgetLabel ?? "";
  }
  set label(o) {
    this._overrideIfSome("label", o);
  }
  set layout(o) {
    "horizontal" !== o && (o = "vertical"), this._set("layout", o);
  }
  set view(o) {
    this.viewModel.view = o;
  }
  get view() {
    return this.viewModel.view;
  }
  render() {
    const o = {
        [m.horizontalLayout]: "horizontal" === this.layout
      },
      {
        canZoomIn: t,
        canZoomOut: e
      } = this.viewModel,
      {
        zoomIn: s,
        zoomOut: i
      } = this.messages;
    return n("div", {
      class: this.classes(m.base, r.widget, o)
    }, n("calcite-button", {
      class: r.widgetButton,
      disabled: !t,
      iconStart: "plus",
      kind: "neutral",
      label: s,
      onclick: this.zoomIn,
      title: s
    }), n("calcite-button", {
      class: r.widgetButton,
      disabled: !e,
      iconStart: "minus",
      kind: "neutral",
      label: i,
      onclick: this.zoomOut,
      title: i
    }));
  }
};
o([t()], c.prototype, "icon", null), o([t()], c.prototype, "label", null), o([t({
  value: "vertical"
})], c.prototype, "layout", null), o([t(), l("esri/widgets/Zoom/t9n/Zoom")], c.prototype, "messages", void 0), o([t()], c.prototype, "view", null), o([t({
  type: a
})], c.prototype, "viewModel", void 0), c = o([e("esri.widgets.Zoom")], c);
const p = c;
export { p as default };