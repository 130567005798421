/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as o } from "../chunks/tslib.es6.js";
import { property as r } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/Logger.js";
import "../core/RandomLCG.js";
import { subclass as s } from "../core/accessorSupport/decorators/subclass.js";
import e from "./View2D.js";
let t = class extends e {
  constructor(o) {
    super(o), this.view2dType = "map";
  }
};
o([r({
  readOnly: !0
})], t.prototype, "view2dType", void 0), t = o([s("esri.views.MapView")], t);
const p = t;
export { p as default };