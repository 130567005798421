/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as e } from "../chunks/tslib.es6.js";
import { byId as t, reparent as s } from "../core/domUtils.js";
import { on as i } from "../core/events.js";
import r from "../core/Logger.js";
import { destroyMaybe as o, removeMaybe as n } from "../core/maybe.js";
import { watch as a, initial as h } from "../core/reactiveUtils.js";
import { addFrameTask as d } from "../core/scheduling.js";
import { property as l } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/RandomLCG.js";
import { subclass as u } from "../core/accessorSupport/decorators/subclass.js";
import c from "./overlay/ViewOverlay.js";
const p = [0, 0];
function m(e) {
  const t = (e.ownerDocument || window.document).defaultView,
    s = e.getBoundingClientRect();
  return p[0] = s.left + (t?.pageXOffset ?? 0), p[1] = s.top + (t?.pageYOffset ?? 0), p;
}
function f(e) {
  e && (e.textContent = "", e.parentNode && e.parentNode.removeChild(e));
}
function g(e) {
  const t = document.createElement("div");
  return e.appendChild(t), t;
}
const y = 16,
  _ = 750,
  v = 512,
  C = 2,
  w = p => {
    let w = class extends p {
      constructor(...e) {
        super(...e), this._freqInfo = {
          freq: y,
          time: _
        }, this._overlayRenderTaskHandle = null, this.height = 0, this.messagesCommon = null, this.overlay = null, this.position = null, this.resizing = !1, this.root = null, this.surface = null, this.suspended = !0, this.ui = null, this.userContent = null, this.width = 0, this.widthBreakpoint = null, this.addHandles([a(() => this.cursor, e => {
          const {
            surface: t
          } = this;
          t && t.setAttribute("data-cursor", e);
        }), a(() => this.navigating, e => {
          const {
            surface: t
          } = this;
          t && t.setAttribute("data-navigating", e.toString());
        })]);
      }
      initialize() {
        this.addHandles([a(() => this.ui, (e, t) => this._handleUIChange(e, t), h), this.on("focus", () => this.notifyChange("focused")), this.on("blur", () => this.notifyChange("focused"))]);
      }
      destroy() {
        this.destroyed || (this.ui = o(this.ui), this.container = null);
      }
      get container() {
        return this._get("container") ?? null;
      }
      set container(e) {
        const i = this._get("container"),
          o = t(e);
        if (o || "string" != typeof e || r.getLogger(this).error("#container", `element with id '${e}' not found`), i === o) return;
        if (this._stopMeasuring(), i && (i.classList.remove("esri-view"), this._overlayRenderTaskHandle && (this._overlayRenderTaskHandle.remove(), this._overlayRenderTaskHandle = null), this.overlay && (this.overlay.destroy(), this._set("overlay", null)), this.root && (f(this.root), this._set("root", null)), this.userContent && (s(this.userContent, i), f(this.userContent), this._set("userContent", null))), !o) return this._set("width", 0), this._set("height", 0), this._set("position", null), this._set("suspended", !0), this._set("surface", null), void this._set("container", null);
        o.classList.add("esri-view");
        const h = document.createElement("div");
        h.className = "esri-view-user-storage", s(o, h), o.appendChild(h), this._set("userContent", h);
        const l = document.createElement("div");
        l.className = "esri-view-root", o.insertBefore(l, o.firstChild), this._set("root", l);
        const u = document.createElement("div");
        u.className = "esri-view-surface", u.setAttribute("role", "application"), u.tabIndex = 0, l.appendChild(u), this._set("surface", u);
        const p = new c();
        l.appendChild(p.surface), this._set("overlay", p), this.addHandles(a(() => p.needsRender, e => {
          e && !this._overlayRenderTaskHandle ? this._overlayRenderTaskHandle = d({
            render: () => this.overlay?.render()
          }) : this._overlayRenderTaskHandle = n(this._overlayRenderTaskHandle);
        })), this.forceDOMReadyCycle(), this._set("container", o), this._startMeasuring();
      }
      get focused() {
        const e = document.activeElement === this.surface;
        return document.hasFocus() && e;
      }
      get size() {
        return [this.width, this.height];
      }
      blur() {
        this.surface?.blur();
      }
      focus() {
        this.surface?.focus();
      }
      pageToContainer(e, t, s) {
        const i = this.position;
        return e -= i ? i[0] : 0, t -= i ? i[1] : 0, s ? (s[0] = e, s[1] = t) : s = [e, t], s;
      }
      containerToPage(e, t, s) {
        const i = this.position;
        return e += i ? i[0] : 0, t += i ? i[1] : 0, s ? (s[0] = e, s[1] = t) : s = [e, t], s;
      }
      _handleUIChange(e, t) {
        this.removeHandles("ui"), t && t !== e && t.destroy(), e && (e.view = this, this.addHandles(a(() => this.root, t => {
          e.container = t ? g(t) : null;
        }, h), "ui")), this._set("ui", e);
      }
      _stopMeasuring() {
        this.removeHandles("measuring"), this._get("resizing") && this._set("resizing", !1);
      }
      _startMeasuring() {
        const e = this._freqInfo;
        e.freq = y, e.time = _, this.addHandles([i(window, "resize", () => {
          e.freq = y, e.time = _;
        }), d({
          prepare: e => {
            const t = this._measure(),
              s = this._freqInfo;
            if (s.time += e.deltaTime, t && (s.freq = y, this._get("resizing") || this._set("resizing", !0)), s.time < s.freq) return;
            s.time = 0;
            const i = this._position();
            s.freq = i || t ? y : Math.min(_, s.freq * C), !t && s.freq >= v && this._get("resizing") && this._set("resizing", !1);
          }
        })], "measuring"), this._measure(), this._position();
      }
      _measure() {
        const e = this.container,
          t = e ? e.clientWidth : 0,
          s = e ? e.clientHeight : 0;
        if (0 === t || 0 === s) return this.suspended || this._set("suspended", !0), !1;
        const i = this.width,
          r = this.height;
        return t === i && s === r ? (this.suspended && this._set("suspended", !1), !1) : (this._set("width", t), this._set("height", s), this.suspended && this._set("suspended", !1), this.emit("resize", {
          oldWidth: i,
          oldHeight: r,
          width: t,
          height: s
        }), !0);
      }
      _position() {
        const e = this.container,
          t = this.position,
          s = e && m(e);
        return !!s && (!t || s[0] !== t[0] || s[1] !== t[1]) && (this._set("position", [s[0], s[1]]), !0);
      }
      forceDOMReadyCycle() {}
    };
    return e([l()], w.prototype, "container", null), e([l({
      readOnly: !0
    })], w.prototype, "focused", null), e([l({
      readOnly: !0
    })], w.prototype, "height", void 0), e([l()], w.prototype, "messagesCommon", void 0), e([l({
      type: c
    })], w.prototype, "overlay", void 0), e([l({
      readOnly: !0
    })], w.prototype, "position", void 0), e([l({
      readOnly: !0
    })], w.prototype, "resizing", void 0), e([l({
      readOnly: !0
    })], w.prototype, "root", void 0), e([l({
      value: null,
      readOnly: !0
    })], w.prototype, "size", null), e([l({
      readOnly: !0
    })], w.prototype, "surface", void 0), e([l({
      readOnly: !0
    })], w.prototype, "suspended", void 0), e([l()], w.prototype, "ui", void 0), e([l({
      readOnly: !0
    })], w.prototype, "userContent", void 0), e([l({
      readOnly: !0
    })], w.prototype, "width", void 0), e([l()], w.prototype, "widthBreakpoint", void 0), w = e([u("esri.views.DOMContainer")], w), w;
  };
export { w as DOMContainer };