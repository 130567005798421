/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import e from "../../config.js";
import t from "../../core/Error.js";
import has from "../../core/has.js";
import o from "../../core/Logger.js";
import r from "../../geometry/Point.js";
import { geographicToWebMercator as i } from "../../geometry/support/webMercatorUtils.js";
import n from "../../portal/Portal.js";
import { project as c } from "../../rest/geometryService/project.js";
import s from "../../rest/support/ProjectParameters.js";
const u = () => o.getLogger("esri.widgets.support.geolocationUtils"),
  a = {
    maximumAge: 0,
    timeout: 15e3,
    enableHighAccuracy: !0
  };
function m() {
  const e = !!has("esri-geolocation");
  return e || u().warn("geolocation-unsupported", "Geolocation unsupported."), e;
}
function l() {
  const e = window.isSecureContext;
  return e || u().warn("insecure-context", "Geolocation requires a secure origin."), e;
}
function g() {
  return m() && l();
}
function p(e) {
  const o = 15e3;
  return e ??= a, new Promise((r, i) => {
    setTimeout(() => i(new t("geolocation:timeout", "getting the current geolocation position timed out")), o), navigator.geolocation.getCurrentPosition(r, i, e ?? void 0);
  });
}
let d;
function f(e, o) {
  if (d) return d(e, o);
  const {
      position: r,
      view: i
    } = e,
    n = v(r),
    c = n?.coords;
  if (!c) throw new t("geometry-service:no-coords", "Geolocation has no coordinates");
  return h(y(c), i, o);
}
function w(e) {
  d = e;
}
function v(e) {
  const t = e && e.coords || {},
    o = {
      accuracy: t.accuracy,
      altitude: t.altitude,
      altitudeAccuracy: t.altitudeAccuracy,
      heading: t.heading,
      latitude: t.latitude,
      longitude: t.longitude,
      speed: t.speed
    };
  return e ? {
    coords: o,
    timestamp: e.timestamp
  } : e;
}
function y({
  longitude: e,
  latitude: t,
  altitude: o
}) {
  return new r({
    longitude: e,
    latitude: t,
    z: o || void 0,
    spatialReference: {
      wkid: 4326
    }
  });
}
function h(e, o, r) {
  if (!o) return Promise.resolve(e);
  const n = o.spatialReference;
  return n.isWGS84 ? Promise.resolve(e) : n.isWebMercator ? Promise.resolve(i(e)) : j(r).then(o => {
    if (!o) throw new t("geometry-service:missing-url", "Geometry service URL is missing");
    const i = new s({
      geometries: [e],
      outSpatialReference: n
    });
    return c(o, i, r).then(e => e[0]);
  });
}
function j(t) {
  if (e.geometryServiceUrl) return Promise.resolve(e.geometryServiceUrl);
  const o = n.getDefault();
  return o.load(t).catch(() => {}).then(() => o.helperServices?.geometry?.url);
}
export { p as getCurrentPosition, w as overridePositionToPoint, f as positionToPoint, g as supported };