/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { getEpsilon as t } from "../../../core/libs/gl-matrix-2/math/common.js";
import { CompareResult as e } from "./Camera.js";
import { defaultSettings as s } from "./Settings.js";
class r {
  constructor(t) {
    this._createCamera = t, this.compared = new e(), this.segmentStart = 0, this.segmentEnd = 1, this.settings = {
      desiredScreenFlow: s.desiredScreenFlow
    }, this.source = t(), this.target = t();
  }
  clone() {
    const t = new r(this._createCamera);
    return t.copyFrom(this), t;
  }
  copyFrom(t) {
    this.update(t.source, t.target, t.settings);
  }
  update(t, e, r) {
    this.source !== t && this.source.copyFrom(t), this.target !== e && this.target.copyFrom(e), this.source.compareTo(this.target, this.compared), this.settings.desiredScreenFlow = r.desiredScreenFlow ?? s.desiredScreenFlow, this.desiredPixelFlow = this.settings.desiredScreenFlow * this.target.size, this.halfWindowSize = this.target.size / 2;
  }
  halfWindowPanAtZoom(t) {
    const e = this.target.pixelsPerPanAtZoom(t);
    return this.halfWindowSize / e;
  }
  get hasZoom() {
    return Math.abs(this.compared.sourceZoom - this.compared.targetZoom) > 1e-5;
  }
  get hasPan() {
    return this.compared.pan > t();
  }
  get hasFov() {
    return Math.abs(this.compared.fov) > t();
  }
  get hasRotate() {
    return this.compared.rotate > t();
  }
}
export { r as Definition };