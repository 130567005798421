/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as o } from "../../../chunks/tslib.es6.js";
import { property as r } from "../../../core/accessorSupport/decorators/property.js";
import "../../../core/has.js";
import "../../../core/Logger.js";
import "../../../core/RandomLCG.js";
import { subclass as s } from "../../../core/accessorSupport/decorators/subclass.js";
import t from "../DefaultUI.js";
let e = class extends t {
  constructor(o) {
    super(o), this.components = ["attribution", "zoom"];
  }
};
o([r()], e.prototype, "components", void 0), e = o([s("esri.views.ui.2d.DefaultUI2D")], e);
const c = e;
export { c as default };