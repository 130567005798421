/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import e from "../../core/Error.js";
function r() {
  return Promise.all([import("./webglDeps.js"), import("./mapViewDeps.js")]);
}
const a = () => r().then(() => import("./layers/TileLayerView2D.js")),
  t = () => r().then(() => import("./layers/FeatureLayerView2D.js")),
  i = {
    "base-dynamic": () => r().then(() => import("./layers/BaseDynamicLayerView2D.js")),
    "base-tile": a,
    "bing-maps": a,
    catalog: () => r().then(() => import("./layers/CatalogLayerView2D.js")),
    "catalog-dynamic-group": () => r().then(() => import("./layers/CatalogDynamicGroupLayerView2D.js")),
    "catalog-footprint": () => r().then(() => import("./layers/CatalogFootprintLayerView2D.js")),
    csv: t,
    "geo-rss": () => r().then(() => import("./layers/GeoRSSLayerView2D.js")),
    feature: t,
    geojson: t,
    parquet: t,
    graphics: () => r().then(() => import("./layers/GraphicsLayerView2D.js")),
    group: () => r().then(() => import("./layers/GroupLayerView2D.js")),
    imagery: () => r().then(() => import("./layers/ImageryLayerView2D.js")),
    "imagery-tile": () => r().then(() => import("./layers/ImageryTileLayerView2D.js")),
    kml: () => r().then(() => import("./layers/KMLLayerView2D.js")),
    "knowledge-graph": () => r().then(() => import("./layers/KnowledgeGraphLayerView2D.js")),
    "link-chart": () => r().then(() => import("./layers/KnowledgeGraphLayerView2D.js")),
    "knowledge-graph-sublayer": t,
    "map-image": () => r().then(() => import("./layers/MapImageLayerView2D.js")),
    "map-notes": () => r().then(() => import("./layers/MapNotesLayerView2D.js")),
    media: () => r().then(() => import("./layers/MediaLayerView2D.js")),
    "ogc-feature": () => r().then(() => import("./layers/OGCFeatureLayerView2D.js")),
    "open-street-map": a,
    "oriented-imagery": t,
    route: () => r().then(() => import("./layers/RouteLayerView2D.js")),
    stream: () => r().then(() => import("./layers/StreamLayerView2D.js")),
    "subtype-group": () => r().then(() => import("./layers/SubtypeGroupLayerView2D.js")),
    tile: a,
    "vector-tile": () => r().then(() => import("./layers/VectorTileLayerView2D.js")),
    video: () => r().then(() => import("./layers/VideoLayerView2D.js")),
    wcs: () => r().then(() => import("./layers/ImageryTileLayerView2D.js")),
    "web-tile": a,
    wfs: t,
    wms: () => r().then(() => import("./layers/WMSLayerView2D.js")),
    wmts: () => r().then(() => import("./layers/WMTSLayerView2D.js")),
    "line-of-sight": null,
    "base-elevation": null,
    "building-scene": null,
    dimension: null,
    elevation: null,
    focusArea: null,
    "integrated-mesh": null,
    "integrated-mesh-3dtiles": null,
    "point-cloud": null,
    viewshed: null,
    voxel: null,
    scene: null,
    unknown: null,
    unsupported: null
  };
function s(r) {
  const a = r.declaredClass ? r.declaredClass.slice(r.declaredClass.lastIndexOf(".") + 1) : "Unknown",
    t = a.replaceAll(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
  return new e(`${t}:view-not-supported`, `${a} is not supported in 2D`);
}
const l = {
  hasLayerViewModule: e => null != i[e.type],
  importLayerView: e => {
    const r = i[e.type];
    if (null == r) throw s(e);
    return r(e);
  }
};
export { l as layerView2DImporter };