/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as t } from "../../chunks/tslib.es6.js";
import "../../geometry.js";
import e from "../../Viewpoint.js";
import i from "../../core/Accessor.js";
import s from "../../core/Error.js";
import has from "../../core/has.js";
import r from "../../core/Logger.js";
import { watch as o, sync as n } from "../../core/reactiveUtils.js";
import { createScreenPoint as a } from "../../core/screenUtils.js";
import c from "../../core/Warning.js";
import { property as p } from "../../core/accessorSupport/decorators/property.js";
import "../../core/RandomLCG.js";
import { subclass as h } from "../../core/accessorSupport/decorators/subclass.js";
import { i as l } from "../../chunks/pe.js";
import d from "../../geometry/Point.js";
import { project as v } from "../../geometry/projection.js";
import { equals as m } from "../../geometry/support/spatialReferenceUtils.js";
import u from "./PaddedViewState.js";
import { centerAt as g, setExtent as w, rotateTo as f, scaleTo as y, copy as _, extentToScale as j, angleBetween as x, resize as S } from "./viewpointUtils.js";
import R from "../../geometry/Extent.js";
let z = class extends i {
  constructor(t) {
    super(t), this.constraints = null, this.ready = !1, this.resizeAlign = "center", this.addHandles([o(() => this.constraints?.version, t => {
      this.constraints && t && this.ready && (this.state.viewpoint = this.constraints.fit(this.state.paddedViewState.viewpoint));
    }, n)]);
  }
  get center() {
    if (!this.ready) return this._get("center");
    const {
      center: t,
      spatialReference: e
    } = this.state.paddedViewState;
    return this.state.commitProperty("id"), new d({
      x: t[0],
      y: t[1],
      spatialReference: e
    });
  }
  set center(t) {
    if (null == t) return;
    if (!this.ready) return void this._set("center", t);
    let e;
    try {
      e = this._project(t, this.state.spatialReference);
    } catch (o) {
      return void r.getLogger(this).error(new s("mapview:invalid-center", "could not project the value in the view's spatial reference", {
        input: t,
        error: o
      }));
    }
    const i = this.viewpoint;
    g(i, i, e), this.viewpoint = i;
  }
  get extent() {
    return this.ready ? (this.state.commitProperty("id"), this.state.paddedViewState.extent.clone()) : this._get("extent");
  }
  set extent(t) {
    if (null == t) return;
    if (!t.width || !t.height) return void r.getLogger(this).error(new s("mapview:invalid-extent", "invalid extent size"));
    if (!this.ready) return this._set("extent", t), this._set("center", void 0), this._set("viewpoint", void 0), this._set("scale", void 0), void this._set("zoom", void 0);
    let e;
    try {
      e = this._project(t, this.state.spatialReference);
    } catch (o) {
      return void r.getLogger(this).error(new s("mapview:invalid-extent", "could not project the value in the view's spatial reference", {
        error: o
      }));
    }
    const i = this.viewpoint;
    w(i, i, e, this.state.size, {
      constraints: this.constraints
    }), this.viewpoint = i;
  }
  get padding() {
    return this.ready ? this.state.padding : this._get("padding");
  }
  set padding(t) {
    this.ready ? (this.state.padding = t, this._set("padding", this.state.padding)) : this._set("padding", t);
  }
  get resolution() {
    return this.ready ? (this.state.commitProperty("id"), this.state.resolution) : 0;
  }
  get rotation() {
    return this.ready ? (this.state.commitProperty("id"), this.state.rotation) : this._get("rotation");
  }
  set rotation(t) {
    if (isNaN(t)) return;
    if (!this.ready) return void this._set("rotation", t);
    const e = this.viewpoint;
    f(e, e, t), this.viewpoint = e;
  }
  get scale() {
    return this.ready ? (this.state.commitProperty("id"), this.state.scale) : this._get("scale");
  }
  set scale(t) {
    if (!t || isNaN(t)) return;
    if (!this.ready) {
      this._set("scale", t), this._set("zoom", void 0);
      const e = this._get("extent");
      return void (e && (this._set("extent", void 0), this._set("center", e.center)));
    }
    const e = this.viewpoint;
    y(e, e, t), this.viewpoint = e;
  }
  get viewpoint() {
    if (!this.ready) return this._get("viewpoint");
    return this.state.paddedViewState.viewpoint.clone();
  }
  set viewpoint(t) {
    if (null == t) return;
    if (!this.ready) return this._set("viewpoint", t), this._set("extent", void 0), this._set("center", void 0), this._set("zoom", void 0), void this._set("scale", void 0);
    let i, o;
    try {
      i = this._project(t, this.state.spatialReference), !t.scale || isNaN(t.scale) ? o = new s("mapview:invalid-viewpoint", `invalid scale value of ${t.scale}`) : null == t.targetGeometry && (o = new s("mapview:invalid-viewpoint", "geometry not defined"));
    } catch (a) {
      o = new s("mapview:invalid-viewpoint", "could not project the value in the view's spatial reference", {
        error: a
      });
    }
    if (o) return void r.getLogger(this).error(o);
    this._scaleBeforeChangingSpatialReference = null;
    const n = new e({
      targetGeometry: new d(),
      scale: 0,
      rotation: 0
    });
    _(n, i), this.constraints?.constrain(n, this.state.paddedViewState.viewpoint), this.state.viewpoint = n, this._set("viewpoint", n);
  }
  get visibleArea() {
    return this.ready ? this.state.visibleArea : null;
  }
  get zoom() {
    return this.ready ? this.constraints?.scaleToZoom(this.scale) ?? -1 : this._get("zoom");
  }
  set zoom(t) {
    if (!(t >= 0)) return;
    if (!this.ready) {
      this._set("zoom", t), this._set("scale", void 0);
      const e = this._get("extent");
      return void (e && (this._set("extent", void 0), this._set("center", e.center)));
    }
    const e = this.constraints?.zoomToScale(t) ?? 0;
    if (!e) return void this._set("zoom", -1);
    const i = this.viewpoint;
    y(i, i, e), this.viewpoint = i, this._set("zoom", this.constraints?.scaleToZoom(this.scale) ?? -1);
  }
  getUserStartupOptions(t) {
    if (!t[0] && !t[1]) return {
      center: void 0,
      rotation: void 0,
      scale: void 0
    };
    const {
        padding: e,
        constraints: i
      } = this,
      s = this._get("center"),
      r = this._get("extent"),
      o = this._get("scale"),
      n = this._get("rotation"),
      a = this._get("viewpoint"),
      c = this._get("zoom"),
      p = null != c && null != i && i.zoomToScale(c) || void 0;
    let h, l, d;
    const v = a?.rotation,
      m = a?.targetGeometry;
    "extent" === m?.type ? h = m : "point" === m?.type && (l = m, d = a?.scale);
    const u = r ?? h;
    return {
      center: s ?? l ?? u?.center,
      rotation: n ?? v,
      scale: (o ?? p ?? d ?? (u && j(u, [t[0] - e.left - e.right, t[1] - e.top - e.bottom]))) || void 0
    };
  }
  startup(t, e, i, s) {
    const o = t.targetGeometry;
    try {
      this._project(t, i);
    } catch (n) {
      r.getLogger(this).warn(new c("mapview:startup-projection-error", "projection of initial viewpoint to the view's spatial reference, defaulting to the initial viewpoint.", {
        center: o.toJSON(),
        spatialReference: i,
        error: n
      })), t.targetGeometry = s || new d({
        x: 0,
        y: 0,
        spatialReference: i
      });
    }
    this.constraints?.fit(t), this._set("state", new u({
      padding: this.padding,
      size: e,
      viewpoint: t
    })), this._set("ready", !0);
  }
  teardown() {
    this._set("ready", !1);
    const {
        center: [t, e],
        spatialReference: i,
        rotation: s,
        scale: r
      } = this.state.paddedViewState,
      o = new d({
        x: t,
        y: e,
        spatialReference: i
      });
    this._set("viewpoint", null), this._set("extent", null), this._set("center", o), this._set("zoom", -1), this._set("rotation", s), this._set("scale", r), this._set("state", null);
  }
  changeSpatialReference(t) {
    const i = this.state.paddedViewState.clone();
    if (null == this._scaleBeforeChangingSpatialReference) this._scaleBeforeChangingSpatialReference = i.scale;else {
      const t = i.viewpoint.clone();
      t.scale = this._scaleBeforeChangingSpatialReference, i.viewpoint = t;
    }
    const s = i.clone(),
      [o, n] = i.center;
    let a = null;
    try {
      a = this._project(new d({
        x: o,
        y: n,
        spatialReference: i.spatialReference
      }), t);
    } catch (h) {
      l() || r.getLogger(this).warn(new c("mapview:spatial-reference-change", "could not project the view's center to the new spatial reference", {
        center: a?.toJSON(),
        spatialReference: t,
        error: h
      }));
    }
    a || (a = new d({
      x: 0,
      y: 0,
      spatialReference: t
    }));
    const p = g(new e({
      targetGeometry: new d(),
      scale: 0,
      rotation: 0
    }), i.viewpoint, a);
    s.viewpoint = p;
    try {
      const e = 20,
        r = [i.size[0] / 2, i.size[1] / 2],
        o = [r[0] + e, r[1]],
        n = i.toMap([0, 0], o),
        {
          x: a,
          y: c
        } = this._project(new d({
          x: n[0],
          y: n[1],
          spatialReference: i.spatialReference
        }), t);
      n[0] = a, n[1] = c, s.toScreen(n, n);
      const h = x(r, n, o),
        l = Math.hypot(n[0] - r[0], n[1] - r[1]) / e;
      !Number.isFinite(l) || Math.abs(l) > 4 ? (p.rotation = 0, p.targetGeometry = new d({
        x: 0,
        y: 0,
        spatialReference: t
      })) : (p.scale *= l, p.scale > has("mapview-srswitch-adjust-rotation-scale-threshold") ? p.rotation = 0 : p.rotation += Number.isFinite(h) ? h : 0);
    } catch {}
    this._get("constraints")?.constrain(p, void 0), this._get("state").viewpoint = p;
  }
  resize(t, e) {
    if (!this.ready) return;
    const i = this.state;
    let s = this.state.paddedViewState.viewpoint;
    const r = this.state.paddedViewState.size.slice();
    i.size = [t, e], S(s, s, r, this.state.paddedViewState.size, this.resizeAlign), s = this.constraints?.constrain(s, void 0) ?? s, this.state.viewpoint = s;
  }
  toMap(t) {
    if (!this.ready) return null;
    const e = [0, 0],
      [i, s] = this.state.toMap(e, [t.x, t.y]),
      r = this.state.spatialReference;
    return new d({
      x: i,
      y: s,
      spatialReference: r
    });
  }
  toScreen(t, e) {
    if (!this.ready) return null;
    const i = this._project(t, this.state.spatialReference),
      s = [i.x, i.y];
    return !1 === e?.pickClosestTarget || this.state.paddedViewState.wrapMapCoordinate(s, s), this.state.toScreen(s, s), a(s[0], s[1]);
  }
  _project(t, e) {
    const i = t?.targetGeometry || t;
    if (!e) return t;
    if (!i) return null;
    if (e.imageCoordinateSystem || i.spatialReference?.imageCoordinateSystem) return t;
    if (m(e, i.spatialReference)) return t;
    const r = v(i, e);
    if (!r) throw new s("mapview:projection-not-possible", "projecting input geometry to target spatial reference returned a null value", {
      geometry: i,
      spatialReference: e
    });
    return V(t) ? (t.targetGeometry = r, t) : r;
  }
};
function V(t) {
  return "esri.Viewpoint" === t?.declaredClass;
}
t([p({
  type: d
})], z.prototype, "center", null), t([p()], z.prototype, "constraints", void 0), t([p({
  type: R
})], z.prototype, "extent", null), t([p({
  value: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  cast: t => ({
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    ...t
  })
})], z.prototype, "padding", null), t([p()], z.prototype, "ready", void 0), t([p()], z.prototype, "resizeAlign", void 0), t([p({
  readOnly: !0
})], z.prototype, "resolution", null), t([p({
  type: Number
})], z.prototype, "rotation", null), t([p({
  type: Number
})], z.prototype, "scale", null), t([p({
  readOnly: !0
})], z.prototype, "state", void 0), t([p({
  type: e
})], z.prototype, "viewpoint", null), t([p({
  readOnly: !0
})], z.prototype, "visibleArea", null), t([p()], z.prototype, "zoom", null), z = t([h("esri.views.2d.ViewStateManager")], z);
export { z as ViewStateManager };