/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as t } from "../../chunks/tslib.es6.js";
import e from "../../core/Accessor.js";
import o from "../../core/Collection.js";
import { property as r } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import "../../core/RandomLCG.js";
import { subclass as s } from "../../core/accessorSupport/decorators/subclass.js";
import { UpdatingHandles as i } from "../../core/support/UpdatingHandles.js";
import "../../libs/maquette/projection.js";
import { createProjector as n } from "../../libs/maquette/projector.js";
let a = class extends e {
  constructor() {
    super(...arguments), this.items = new o(), this._watchUpdatingTracking = new i(), this._callbacks = new Map(), this._projector = n(), this._hiddenProjector = n();
  }
  get needsRender() {
    return this.items.length > 0;
  }
  get updating() {
    return this._watchUpdatingTracking?.updating ?? !1;
  }
  initialize() {
    const t = document.createElement("div");
    t.className = "esri-overlay-surface", this._set("surface", t), this._hiddenSurface = document.createElement("div"), this._hiddenSurface.setAttribute("style", "visibility: hidden;"), t.appendChild(this._hiddenSurface), this._watchUpdatingTracking.addOnCollectionChange(() => this.items, t => {
      for (const e of t.added) {
        const t = () => e.render();
        this._callbacks.set(e, t), this._projector.append(this.surface, t);
      }
      for (const e of t.removed) {
        const t = this._projector.detach(this._callbacks.get(e));
        this.surface.removeChild(t.domNode), this._callbacks.delete(e);
      }
    });
  }
  addItem(t) {
    this.items.add(t);
  }
  removeItem(t) {
    this.items.remove(t);
  }
  destroy() {
    this.items.removeAll(), this._callbacks.forEach(t => this._projector.detach(t)), this._callbacks = null, this._projector = null, this._watchUpdatingTracking.destroy();
  }
  render() {
    this._projector.renderNow();
  }
  computeBoundingRect(t) {
    const e = this._hiddenSurface,
      o = this._hiddenProjector;
    let r;
    const s = () => (r = t.render(), r);
    o.append(e, s), o.renderNow();
    const i = {
      left: 0,
      top: 0,
      right: 0,
      bottom: 0
    };
    if (r?.domNode) {
      const t = r.domNode.getBoundingClientRect();
      i.left = t.left, i.top = t.top, i.right = t.right, i.bottom = t.bottom;
    }
    for (o.detach(s); e.firstChild;) e.removeChild(e.firstChild);
    return i;
  }
  overlaps(t, e) {
    const o = this.computeBoundingRect(t),
      r = this.computeBoundingRect(e);
    return Math.max(o.left, r.left) <= Math.min(o.right, r.right) && Math.max(o.top, r.top) <= Math.min(o.bottom, r.bottom);
  }
  get hasVisibleItems() {
    return this.items.some(t => t.visible);
  }
  async prepare() {
    await document.fonts.load(this._fontString()).catch(() => {});
  }
  renderCanvas(t, e) {
    const o = !!e?.disableDecorations;
    if (!this.items.some(t => t.visible && !(o && t.isDecoration))) return;
    const r = t.getContext("2d");
    r.save(), r.font = this._fontString(), this.items.forEach(t => {
      o && t.isDecoration || (r.save(), t.renderCanvas(r), r.restore());
    }), r.restore();
  }
  _fontString() {
    return `10px ${getComputedStyle(this.surface).fontFamily}`;
  }
};
t([r({
  readOnly: !0
})], a.prototype, "surface", void 0), t([r({
  readOnly: !0
})], a.prototype, "items", void 0), t([r({
  readOnly: !0
})], a.prototype, "needsRender", null), t([r({
  readOnly: !0
})], a.prototype, "_watchUpdatingTracking", void 0), t([r({
  readOnly: !0
})], a.prototype, "updating", null), a = t([s("esri.views.overlay.ViewOverlay")], a);
const c = a;
export { c as default };