/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as t } from "../../chunks/tslib.es6.js";
import e from "../../core/Accessor.js";
import { watch as i, sync as o } from "../../core/reactiveUtils.js";
import { property as s } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import "../../core/RandomLCG.js";
import { subclass as r } from "../../core/accessorSupport/decorators/subclass.js";
import { set as p, subtract as d, copy as a } from "../../core/libs/gl-matrix-2/math/vec2.js";
import { create as n } from "../../core/libs/gl-matrix-2/factories/vec2f64.js";
import { addPadding as c } from "./viewpointUtils.js";
import h from "./ViewState.js";
var l, m;
let g = l = class extends e {
  constructor() {
    super(...arguments), this.left = 0, this.top = 0, this.right = 0, this.bottom = 0;
  }
  clone() {
    return new l({
      left: this.left,
      top: this.top,
      right: this.right,
      bottom: this.bottom
    });
  }
};
t([s()], g.prototype, "left", void 0), t([s()], g.prototype, "top", void 0), t([s()], g.prototype, "right", void 0), t([s()], g.prototype, "bottom", void 0), g = l = t([r("esri.views.2d.PaddedViewState.Padding")], g);
let w = m = class extends h {
  constructor(...t) {
    super(...t), this.paddedViewState = new h(), this._updateContent = (() => {
      const t = n();
      return () => {
        const e = this._get("size"),
          i = this._get("padding");
        if (!e || !i) return;
        const o = this.paddedViewState;
        p(t, i.left + i.right, i.top + i.bottom), d(t, e, t), a(o.size, t);
        const s = o.viewpoint;
        s && (this.viewpoint = s);
      };
    })(), this.addHandles(i(() => [this.size, this.padding], () => this._updateContent(), o)), this.padding = new g(), this.size = [0, 0];
  }
  set padding(t) {
    this._set("padding", t || new g());
  }
  set viewpoint(t) {
    if (t) {
      this.paddedViewState.viewpoint = t;
      let e = t;
      const i = this._get("padding");
      i && (e = c(t.clone(), t, this._get("size"), i));
      const {
          targetGeometry: o,
          rotation: s,
          scale: r
        } = e,
        {
          x: p,
          y: d,
          spatialReference: a
        } = o,
        n = this._viewpoint2D;
      n.center[0] = p, n.center[1] = d, n.rotation = s, n.scale = r, n.spatialReference = a, this._update();
    }
  }
  clone() {
    return new m({
      padding: this.padding.clone(),
      size: this.size.slice(),
      viewpoint: this.paddedViewState.viewpoint.clone(),
      pixelRatio: this.pixelRatio
    });
  }
};
t([s()], w.prototype, "paddedViewState", void 0), t([s({
  type: g
})], w.prototype, "padding", null), t([s()], w.prototype, "viewpoint", null), w = m = t([r("esri.views.2d.PaddedViewState")], w);
const f = w;
export { f as default };