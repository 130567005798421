/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { Seconds as t } from "../../../core/time.js";
let e = class {
  constructor() {
    this.segments = new Array();
  }
  get time() {
    return this.segments.reduce((e, o) => t(e + o.time), t(0));
  }
  interpolateComponentsAt(t, e) {
    t = Math.min(Math.max(t, 0), 1), t *= this.time;
    let o = 0,
      s = 0;
    const n = this.definition,
      a = this.segments.reduce((t, e) => t || e.definition.hasZoom, !1);
    for (let i = 0; i < this.segments.length; i++) {
      const m = this.segments[i],
        r = m.definition;
      if (t <= m.time || i === this.segments.length - 1) {
        if (this.segmentInterpolateComponentsAt(m, 0 === m.time ? 0 : t / m.time, e), n.hasPan && !isNaN(e.pan) && isFinite(n.compared.pan) ? e.pan = (o + r.compared.pan * e.pan) / n.compared.pan : e.pan = 1, n.hasRotate && !isNaN(e.rotate) && isFinite(n.compared.rotate) ? e.rotate = (s + r.compared.rotate * e.rotate) / n.compared.rotate : e.rotate = 1, a && !isNaN(e.zoom) && isFinite(r.compared.targetZoom)) {
          const {
              sourceZoom: t,
              targetZoom: o
            } = r.compared,
            s = e.zoom * (o - t) + t,
            n = this.segments[0].definition.compared.sourceZoom,
            a = this.segments[this.segments.length - 1].definition.compared.targetZoom,
            i = Math.abs(o - n) > Math.abs(t - n) ? o : t;
          e.zoomOffset = i - a, e.zoom = (s - n) / (i - n);
        } else e.zoom = 1;
        return e;
      }
      t -= m.time, o += r.compared.pan, s += r.compared.rotate;
    }
  }
  segmentInterpolateComponentsAt(t, e, o) {
    t.interpolateComponentsAt(e, o);
  }
};
export { e as Path };