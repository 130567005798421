/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as e } from "../chunks/tslib.es6.js";
import o from "../core/Logger.js";
import "../core/has.js";
import "../core/RandomLCG.js";
import "../core/Error.js";
import { subclass as r } from "../core/accessorSupport/decorators/subclass.js";
import { OwningCollection as s } from "../core/support/OwningCollection.js";
let t = class extends s {
  constructor(e) {
    super(e), this.addHandles(this.on("before-add", e => {
      null != e.item && e.item.parent === this.owner && (o.getLogger(this).warn("Analysis inside the collection must be unique. Not adding this element again."), e.preventDefault());
    }));
  }
  _own(e) {
    e.parent = this.owner;
  }
  _release(e) {
    e.parent = null;
  }
};
t = e([r("esri.support.AnalysesCollection")], t);
export { t as AnalysesCollection };