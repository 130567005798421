/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import "../../geometry.js";
import { distance as t } from "../../core/libs/gl-matrix-2/math/vec2.js";
import { projectPointOnLine as e } from "./coordsUtils.js";
import o from "../Point.js";
function n(n, m) {
  const {
      spatialReference: r
    } = m,
    i = [m.x, m.y];
  let s = Number.POSITIVE_INFINITY,
    x = 0,
    a = 0;
  const c = [0, 0],
    f = "extent" === n.type ? [[[n.xmin, n.ymin], [n.xmin, n.ymax], [n.xmax, n.ymax], [n.xmax, n.ymin], [n.xmin, n.ymin]]] : n.rings;
  for (const o of f) for (let n = 0; n < o.length - 1; n++) {
    e(c, i, o, n);
    const m = t(i, c);
    m < s && (s = m, x = c[0], a = c[1]);
  }
  return {
    coordinate: new o({
      x,
      y: a,
      spatialReference: r
    }),
    distance: s
  };
}
export { n as nearestCoordinate };