/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { InputHandler as i } from "../../../input/InputHandler.js";
import { onVisibilityChange as t } from "../../../input/VisibilityChange.js";
class e extends i {
  constructor(i, e, s) {
    super(!0), this.view = i, this.keys = e, this._isSticky = !1, this._pressedKeys = new Set(), this._timeout = void 0, this._currentDirection = void 0, this._stickyKeyDuration = 200, this._handleKey = i => {
      const t = this._keyMap[i.data.key];
      if (i.modifiers.has("Meta") || i.modifiers.has("Control")) return void this._stopMovement();
      if (null == t) return;
      i.stopPropagation(), i.preventDefault();
      const e = "key-down" === i.type;
      if (this._pressedKeys[e ? "add" : "delete"](t), e) {
        if (this._direction === t) return;
        this._direction = t, this._isSticky = !1, this._setTimeout(() => {
          this._isSticky && this._handlePopKey();
        });
      } else {
        const i = void 0 === this._timeout,
          t = this._pressedKeys.size > 0;
        i || t ? this._handlePopKey() : this._isSticky = !0;
      }
    }, this._handlePopKey = () => {
      this._direction = Array.from(this._pressedKeys).pop(), null == this._direction && this._stopMovement();
    }, this._stopMovement = () => {
      this._isSticky = !1, this._direction = void 0, this._pressedKeys.clear(), this._setTimeout(void 0);
    }, this._keyMap = {
      [e.left]: "left",
      [e.right]: "right",
      [e.up]: "up",
      [e.down]: "down"
    }, this.registerIncoming("key-down", s, this._handleKey), this.registerIncoming("key-up", s, this._handleKey), this.registerIncoming("blur", s, this._stopMovement), this._visibilityHandle = t(i => i ? null : this._stopMovement());
  }
  onUninstall() {
    this._stopMovement(), this._visibilityHandle?.remove();
  }
  get _direction() {
    return this._currentDirection;
  }
  set _direction(i) {
    const t = null != this._currentDirection;
    if (null != i) {
      if (t || this.view.mapViewNavigation.begin(), this._currentDirection !== i) switch (i) {
        case "left":
          this.view.mapViewNavigation.continuousPanLeft();
          break;
        case "right":
          this.view.mapViewNavigation.continuousPanRight();
          break;
        case "up":
          this.view.mapViewNavigation.continuousPanUp();
          break;
        case "down":
          this.view.mapViewNavigation.continuousPanDown();
      }
    } else t && this.view.mapViewNavigation.stop();
    this._currentDirection = i;
  }
  _setTimeout(i) {
    clearTimeout(this._timeout), this._timeout = void 0 === i ? void 0 : setTimeout(() => {
      this._timeout = void 0, i();
    }, this._stickyKeyDuration);
  }
}
export { e as KeyPan };