/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as t } from "../chunks/tslib.es6.js";
import s from "../core/Error.js";
import { EsriPromise as e } from "../core/Promise.js";
import { createResolver as r, isPromiseLike as o } from "../core/promiseUtils.js";
import { property as i } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/Logger.js";
import "../core/RandomLCG.js";
import { subclass as n } from "../core/accessorSupport/decorators/subclass.js";
let p = class extends e {
  constructor(t) {
    super(t), this.state = "running", this.target = null, this._resolver = null;
  }
  initialize() {
    this._resolver = r(), this.addResolvingPromise(this._resolver.promise);
  }
  get done() {
    return "finished" === this.state || "stopped" === this.state;
  }
  stop() {
    "stopped" !== this.state && "finished" !== this.state && (this._set("state", "stopped"), this._resolver?.reject(new s("ViewAnimation stopped")));
  }
  finish() {
    "stopped" !== this.state && "finished" !== this.state && (this._set("state", "finished"), this._resolver?.resolve());
  }
  update(t, s) {
    s || (s = o(t) ? "waiting-for-target" : "running"), this._set("target", t), this._set("state", s);
  }
};
t([i({
  readOnly: !0
})], p.prototype, "done", null), t([i({
  readOnly: !0,
  type: String
})], p.prototype, "state", void 0), t([i()], p.prototype, "target", void 0), p = t([n("esri.views.ViewAnimation")], p), function (t) {
  t.State = {
    RUNNING: "running",
    STOPPED: "stopped",
    FINISHED: "finished",
    WAITING_FOR_TARGET: "waiting-for-target"
  };
}(p || (p = {}));
const a = p;
export { a as default };