/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import "../../core/has.js";
import { makeHandle as t } from "../../core/handleUtils.js";
import { rad2deg as e } from "../../core/mathUtils.js";
import { createScreenPoint as n } from "../../core/screenUtils.js";
import { InputHandler as a } from "./InputHandler.js";
import { ViewEventPriorities as i } from "./InputManager.js";
export { MouseButton } from "./IViewEvents.js";
const r = ["click", "double-click", "immediate-click", "immediate-double-click", "hold", "drag", "key-down", "key-up", "pointer-down", "pointer-move", "pointer-up", "pointer-drag", "mouse-wheel", "pointer-enter", "pointer-leave", "gamepad", "focus", "blur"],
  o = {};
function s(t) {
  return !!o[t];
}
function p(t) {
  for (const e of t) if (!s(e)) return !1;
  return !0;
}
r.forEach(t => {
  o[t] = !0;
});
class c {
  constructor(t) {
    this._handlers = new Map(), this._counter = 0, this._handlerCounts = new Map(), this.view = t, this.inputManager = null;
  }
  connect(t) {
    t && this.disconnect(), this.inputManager = t, this._handlers.forEach(({
      handler: t,
      priority: e
    }, n) => this.inputManager?.installHandlers(n, [t], e));
  }
  disconnect() {
    this.inputManager && this._handlers.forEach((t, e) => this.inputManager?.uninstallHandlers(e)), this.inputManager = null;
  }
  destroy() {
    this.disconnect(), this._handlers.clear(), this.view = null;
  }
  on(e, n, a, r) {
    const o = Array.isArray(e) ? e : e.split(",");
    if (!p(o)) return o.some(s) && console.error("Error: registering input events and other events on the view at the same time is not supported."), null;
    let c, u;
    Array.isArray(n) ? u = n : (c = n, u = []), "function" == typeof a ? c = a : r = a, r = null != r ? r : i.DEFAULT;
    const m = this._createUniqueGroupName(),
      d = new l(this.view, o, u, c);
    this._handlers.set(m, {
      handler: d,
      priority: r
    });
    for (const t of o) {
      const e = this._handlerCounts.get(t) || 0;
      this._handlerCounts.set(t, e + 1);
    }
    return this.inputManager && this.inputManager.installHandlers(m, [d], r), t(() => this._removeHandler(m, o));
  }
  hasHandler(t) {
    return !!this._handlerCounts.get(t);
  }
  _removeHandler(t, e) {
    if (this._handlers.has(t)) {
      this._handlers.delete(t);
      for (const t of e) {
        const e = this._handlerCounts.get(t);
        void 0 === e || (1 === e ? this._handlerCounts.delete(t) : this._handlerCounts.set(t, e - 1));
      }
    }
    this.inputManager && this.inputManager.uninstallHandlers(t);
  }
  _createUniqueGroupName() {
    return this._counter += 1, `viewEvents_${this._counter}`;
  }
}
class l extends a {
  constructor(t, e, n, a) {
    super(!0), this._latestDragStart = void 0, this.view = t;
    for (const i of e) switch (i) {
      case "click":
        this.registerIncoming("click", n, e => a(d(t, e)));
        break;
      case "double-click":
        this.registerIncoming("double-click", n, e => a(g(t, e)));
        break;
      case "immediate-click":
        this.registerIncoming("immediate-click", n, e => a(y(t, e)));
        break;
      case "immediate-double-click":
        this.registerIncoming("immediate-double-click", n, e => a(v(t, e)));
        break;
      case "hold":
        this.registerIncoming("hold", n, e => a(h(t, e)));
        break;
      case "drag":
        this.registerIncoming("drag", n, t => {
          const e = this._wrapDrag(t);
          e && a(e);
        });
        break;
      case "key-down":
        this.registerIncoming("key-down", n, t => a(f(t)));
        break;
      case "key-up":
        this.registerIncoming("key-up", n, t => a(k(t)));
        break;
      case "pointer-down":
        this.registerIncoming("pointer-down", n, t => a(I(t, "pointer-down")));
        break;
      case "pointer-move":
        this.registerIncoming("pointer-move", n, t => a(I(t, "pointer-move")));
        break;
      case "pointer-up":
        this.registerIncoming("pointer-up", n, t => a(I(t, "pointer-up")));
        break;
      case "pointer-drag":
        this.registerIncoming("pointer-drag", n, t => a(D(t)));
        break;
      case "mouse-wheel":
        this.registerIncoming("mouse-wheel", n, t => a(P(t)));
        break;
      case "pointer-enter":
        this.registerIncoming("pointer-enter", n, t => a(I(t, "pointer-enter")));
        break;
      case "pointer-leave":
        this.registerIncoming("pointer-leave", n, t => a(I(t, "pointer-leave")));
        break;
      case "gamepad":
        this.registerIncoming("gamepad", n, t => {
          a(_(t));
        });
        break;
      case "focus":
        this.registerIncoming("focus", n, t => {
          a(u(t));
        });
        break;
      case "blur":
        this.registerIncoming("blur", n, t => {
          a(m(t));
        });
    }
  }
  _wrapDrag(t) {
    const n = t.data,
      {
        x: a,
        y: i
      } = n.center,
      {
        action: r,
        pointerType: o,
        button: s
      } = n;
    if ("start" === r && (this._latestDragStart = n), !this._latestDragStart) return;
    const p = n.pointer.native,
      c = n.buttons,
      {
        cancelable: l,
        timestamp: u
      } = t,
      m = {
        x: this._latestDragStart.center.x,
        y: this._latestDragStart.center.y
      };
    return "end" === r && (this._latestDragStart = void 0), {
      type: "drag",
      action: r,
      x: a,
      y: i,
      origin: m,
      pointerType: o,
      button: s,
      buttons: c,
      radius: n.radius,
      angle: e(n.angle),
      native: p,
      timestamp: u,
      cancelable: l,
      stopPropagation: () => t.stopPropagation(),
      async: e => t.async(e),
      preventDefault: () => t.preventDefault()
    };
  }
}
function u(t) {
  return {
    type: "focus",
    timestamp: t.timestamp,
    native: t.data.native,
    cancelable: t.cancelable,
    stopPropagation: () => t.stopPropagation(),
    async: e => t.async(e),
    preventDefault: () => t.preventDefault()
  };
}
function m(t) {
  return {
    type: "blur",
    timestamp: t.timestamp,
    native: t.data.native,
    cancelable: t.cancelable,
    stopPropagation: () => t.stopPropagation(),
    async: e => t.async(e),
    preventDefault: () => t.preventDefault()
  };
}
function d(t, e) {
  const {
      pointerType: a,
      button: i,
      buttons: r,
      x: o,
      y: s,
      native: p,
      eventId: c
    } = e.data,
    {
      cancelable: l,
      timestamp: u
    } = e;
  return {
    type: "click",
    pointerType: a,
    button: i,
    buttons: r,
    x: o,
    y: s,
    native: p,
    timestamp: u,
    screenPoint: n(o, s),
    mapPoint: b(t, o, s),
    eventId: c,
    cancelable: l,
    stopPropagation: () => e.stopPropagation(),
    async: t => e.async(t),
    preventDefault: () => e.preventDefault()
  };
}
function g(t, e) {
  const {
      pointerType: n,
      button: a,
      buttons: i,
      x: r,
      y: o,
      native: s,
      eventId: p
    } = e.data,
    {
      cancelable: c,
      timestamp: l
    } = e;
  return {
    type: "double-click",
    pointerType: n,
    button: a,
    buttons: i,
    x: r,
    y: o,
    native: s,
    timestamp: l,
    mapPoint: b(t, r, o),
    eventId: p,
    cancelable: c,
    stopPropagation: () => e.stopPropagation(),
    async: t => e.async(t),
    preventDefault: () => e.preventDefault()
  };
}
function y(t, e) {
  const {
      pointerType: n,
      button: a,
      buttons: i,
      x: r,
      y: o,
      native: s,
      eventId: p
    } = e.data,
    c = s.pointerId,
    {
      cancelable: l,
      timestamp: u
    } = e;
  return {
    type: "immediate-click",
    pointerId: c,
    pointerType: n,
    button: a,
    buttons: i,
    x: r,
    y: o,
    native: s,
    timestamp: u,
    mapPoint: b(t, r, o),
    eventId: p,
    cancelable: l,
    stopPropagation: () => e.stopPropagation(),
    async: t => e.async(t),
    preventDefault: () => e.preventDefault()
  };
}
function v(t, e) {
  const {
      pointerType: n,
      button: a,
      buttons: i,
      x: r,
      y: o,
      native: s,
      eventId: p
    } = e.data,
    c = s.pointerId,
    {
      cancelable: l,
      timestamp: u
    } = e;
  return {
    type: "immediate-double-click",
    pointerId: c,
    pointerType: n,
    button: a,
    buttons: i,
    x: r,
    y: o,
    native: s,
    timestamp: u,
    mapPoint: b(t, r, o),
    eventId: p,
    cancelable: l,
    stopPropagation: () => e.stopPropagation(),
    async: t => e.async(t),
    preventDefault: () => e.preventDefault()
  };
}
function h(t, e) {
  const {
      pointerType: n,
      button: a,
      buttons: i,
      x: r,
      y: o,
      native: s
    } = e.data,
    {
      cancelable: p,
      timestamp: c
    } = e;
  return {
    type: "hold",
    pointerType: n,
    button: a,
    buttons: i,
    x: r,
    y: o,
    native: s,
    timestamp: c,
    mapPoint: b(t, r, o),
    cancelable: p,
    stopPropagation: () => e.stopPropagation(),
    async: t => e.async(t),
    preventDefault: () => e.preventDefault()
  };
}
function b(t, e, a) {
  return t.toMap(n(e, a), {
    exclude: []
  });
}
function f(t) {
  const {
      key: e,
      repeat: n,
      native: a
    } = t.data,
    {
      cancelable: i,
      timestamp: r
    } = t;
  return {
    type: "key-down",
    key: e,
    repeat: n,
    native: a,
    timestamp: r,
    cancelable: i,
    stopPropagation: () => t.stopPropagation(),
    async: e => t.async(e),
    preventDefault: () => t.preventDefault()
  };
}
function k(t) {
  const {
      key: e,
      native: n
    } = t.data,
    {
      cancelable: a,
      timestamp: i
    } = t;
  return {
    type: "key-up",
    key: e,
    native: n,
    timestamp: i,
    cancelable: a,
    stopPropagation: () => t.stopPropagation(),
    async: e => t.async(e),
    preventDefault: () => t.preventDefault()
  };
}
function I(t, e) {
  const {
      x: n,
      y: a,
      button: i,
      buttons: r,
      native: o,
      eventId: s
    } = t.data,
    p = o.pointerId,
    c = o.pointerType,
    {
      cancelable: l,
      timestamp: u
    } = t;
  return {
    type: e,
    x: n,
    y: a,
    pointerId: p,
    pointerType: c,
    button: i,
    buttons: r,
    native: o,
    timestamp: u,
    eventId: s,
    cancelable: l,
    stopPropagation: () => t.stopPropagation(),
    async: e => t.async(e),
    preventDefault: () => t.preventDefault()
  };
}
function D(t) {
  const {
      x: e,
      y: n,
      buttons: a,
      native: i,
      eventId: r
    } = t.data.currentEvent,
    {
      button: o
    } = t.data.startEvent,
    s = t.data.startEvent.native.pointerId,
    p = t.data.startEvent.native.pointerType,
    c = t.data.action,
    l = {
      x: t.data.startEvent.x,
      y: t.data.startEvent.y
    },
    {
      cancelable: u,
      timestamp: m
    } = t;
  return {
    type: "pointer-drag",
    x: e,
    y: n,
    pointerId: s,
    pointerType: p,
    button: o,
    buttons: a,
    action: c,
    origin: l,
    native: i,
    timestamp: m,
    eventId: r,
    cancelable: u,
    stopPropagation: () => t.stopPropagation(),
    async: e => t.async(e),
    preventDefault: () => t.preventDefault()
  };
}
function P(t) {
  const {
      cancelable: e,
      data: n,
      timestamp: a
    } = t,
    {
      x: i,
      y: r,
      deltaY: o,
      native: s
    } = n;
  return {
    type: "mouse-wheel",
    x: i,
    y: r,
    deltaY: o,
    native: s,
    timestamp: a,
    cancelable: e,
    stopPropagation: () => t.stopPropagation(),
    async: e => t.async(e),
    preventDefault: () => t.preventDefault()
  };
}
function _(t) {
  const {
      action: e,
      state: n,
      device: a
    } = t.data,
    {
      cancelable: i,
      timestamp: r
    } = t,
    {
      buttons: o,
      axes: s
    } = n;
  return {
    type: "gamepad",
    device: a,
    timestamp: r,
    action: e,
    buttons: o,
    axes: s,
    cancelable: i,
    stopPropagation: () => t.stopPropagation(),
    async: e => t.async(e),
    preventDefault: () => t.preventDefault()
  };
}
export { c as ViewEvents, r as eventTypes };