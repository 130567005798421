/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
const t = t => t,
  u = t => t * t,
  o = t => 1 - u(1 - t),
  i = t => t < .5 ? u(2 * t) / 2 : (o(2 * (t - .5)) + 1) / 2,
  n = t => t * t * t,
  c = t => 1 - n(1 - t),
  a = t => t < .5 ? n(2 * t) / 2 : (c(2 * (t - .5)) + 1) / 2,
  s = t => t * t * t * t,
  q = t => 1 - s(1 - t),
  r = t => t < .5 ? s(2 * t) / 2 : (q(2 * (t - .5)) + 1) / 2,
  e = t => t * t * t * t * t,
  b = t => 1 - e(1 - t),
  d = t => t < .5 ? e(2 * t) / 2 : (b(2 * (t - .5)) + 1) / 2,
  h = t => 1 - Math.cos(t * Math.PI / 2),
  p = t => 1 - h(1 - t),
  x = t => t < .5 ? h(2 * t) / 2 : (p(2 * (t - .5)) + 1) / 2,
  M = t => 2 ** (10 * (t - 1)),
  f = t => 1 - M(1 - t),
  l = t => t < .5 ? M(2 * t) / 2 : (f(2 * (t - .5)) + 1) / 2,
  I = t => -(Math.sqrt(1 - t * t) - 1),
  P = t => 1 - I(1 - t),
  g = t => t < .5 ? I(2 * t) / 2 : (P(2 * (t - .5)) + 1) / 2;
function j(t) {
  const u = 2 * (t - Math.sqrt((t - 1) * t)),
    o = u / 2 / t;
  return i => i < o ? t * i * i : u * i - u + 1;
}
function k(t, u) {
  return (o, i) => o < u ? u * t(o / u, i) : 1 - t((1 - o) / (1 - u), i) * (1 - u);
}
const m = k(j(1), 1),
  v = k(j(1), 0),
  w = k(j(1), .5),
  y = k(j(2), 1),
  z = k(j(2), 0),
  A = k(j(2), .5),
  B = k(j(3), 1),
  C = k(j(3), 0),
  D = k(j(3), .5),
  E = k(j(4), 1),
  F = k(j(4), 0),
  G = k(j(4), .5),
  H = {
    linear: t,
    "in-quad": u,
    "out-quad": o,
    "in-out-quad": i,
    "in-coast-quad": m,
    "out-coast-quad": v,
    "in-out-coast-quad": w,
    "in-cubic": n,
    "out-cubic": c,
    "in-out-cubic": a,
    "in-coast-cubic": y,
    "out-coast-cubic": z,
    "in-out-coast-cubic": A,
    "in-quart": s,
    "out-quart": q,
    "in-out-quart": r,
    "in-coast-quart": B,
    "out-coast-quart": C,
    "in-out-coast-quart": D,
    "in-quint": e,
    "out-quint": b,
    "in-out-quint": d,
    "in-coast-quint": E,
    "out-coast-quint": F,
    "in-out-coast-quint": G,
    "in-sine": h,
    "out-sine": p,
    "in-out-sine": x,
    "in-expo": M,
    "out-expo": f,
    "in-out-expo": l,
    "in-circ": I,
    "out-circ": P,
    "in-out-circ": g
  };
export { H as EasingFunctions, I as inCirc, y as inCoastCubic, m as inCoastQuad, B as inCoastQuart, E as inCoastQuint, n as inCubic, M as inExpo, g as inOutCirc, A as inOutCoastCubic, w as inOutCoastQuad, D as inOutCoastQuart, G as inOutCoastQuint, a as inOutCubic, l as inOutExpo, i as inOutQuad, r as inOutQuart, d as inOutQuint, x as inOutSine, u as inQuad, s as inQuart, e as inQuint, h as inSine, t as linear, P as outCirc, z as outCoastCubic, v as outCoastQuad, C as outCoastQuart, F as outCoastQuint, c as outCubic, f as outExpo, o as outQuad, q as outQuart, b as outQuint, p as outSine };