/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as e } from "../chunks/tslib.es6.js";
import t from "../Map.js";
import i from "../core/Accessor.js";
import { createTask as r } from "../core/asyncUtils.js";
import s from "../core/Collection.js";
import o from "../core/CollectionFlattener.js";
import a from "../core/Error.js";
import n from "../core/Evented.js";
import l from "../core/Handles.js";
import { makeHandle as p } from "../core/handleUtils.js";
import "../core/has.js";
import h from "../core/Loadable.js";
import d from "../core/Logger.js";
import { destroyMaybe as u, abortMaybe as c } from "../core/maybe.js";
import { EsriPromiseMixin as y } from "../core/Promise.js";
import { after as m } from "../core/promiseUtils.js";
import { watch as g, whenOnce as f, when as w, sync as v, syncAndInitial as M } from "../core/reactiveUtils.js";
import { property as V } from "../core/accessorSupport/decorators/property.js";
import "../core/RandomLCG.js";
import { subclass as _ } from "../core/accessorSupport/decorators/subclass.js";
import { owningCollectionProperty as R } from "../core/support/OwningCollection.js";
import { UpdatingHandles as j } from "../core/support/UpdatingHandles.js";
import S from "../geometry/Extent.js";
import E from "../geometry/HeightModelInfo.js";
import T from "../geometry/SpatialReference.js";
import { equals as F } from "../geometry/support/spatialReferenceUtils.js";
import { AnalysesCollection as x } from "../support/AnalysesCollection.js";
import { GraphicsCollection as b } from "../support/GraphicsCollection.js";
import L from "../time/TimeExtent.js";
import { system as k, isTimeZoneValid as O } from "../time/timeZoneUtils.js";
import C from "./BasemapView.js";
import Z from "./LayerViewManager.js";
import I from "./Magnifier.js";
import D from "./SelectionManager.js";
import H from "./Theme.js";
import { ToolViewManager as P } from "./ToolViewManager.js";
import q from "./input/Input.js";
import { ViewEvents as U } from "./input/ViewEvents.js";
import A from "./navigation/Navigation.js";
import { DefaultsFromMap as z } from "./support/DefaultsFromMap.js";
var W;
let G = W = class extends n.EventedMixin(y(i)) {
  constructor(e) {
    super(e), this._userSpatialReference = null, this._cursor = null, this.handles = new l(), this.updatingHandles = new j(), this.allLayerViews = new o({
      getCollections: () => [this.basemapView?.baseLayerViews, this.groundView?.layerViews, this.layerViews, this.basemapView?.referenceLayerViews],
      getChildrenFunction: B
    }), this.groundView = null, this.basemapView = null, this.fatalError = null, this.graphics = new b(), this.analyses = new x(), this.typeSpecificPreconditionsReady = !0, this.layerViews = new s(), this.magnifier = new I(), this.padding = {
      left: 0,
      top: 0,
      right: 0,
      bottom: 0
    }, this.ready = !1, this.spatialReferenceWarningDelay = 1e3, this.supportsGround = !0, this.type = null, this.scale = null, this.updating = !1, this.initialExtentRequired = !0, this.input = new q(), this.navigation = new A(), this.layerViewManager = null, this.analysisViewManager = null, this.isHeightModelInfoRequired = !1, this.width = null, this.height = null, this.resizing = !1, this.suspended = !1, this.viewEvents = new U(this), this.persistableViewModels = new s(), this._isValid = !1, this._readyCycleForced = !1, this._lockedSpatialReference = null, this._userTimeZone = null, this._lockedTimeZone = null, this._userTimeExtent = null, this._lockedTimeExtent = null, this.theme = null, this.handles.add(g(() => this.preconditionsReady, e => {
      const t = this.ready;
      if (e ? (this._lockedSpatialReference = this.spatialReference, this._lockedTimeZone = this.timeZone, this._lockedTimeExtent = this.timeExtent, W.views.add(this)) : (this._lockedSpatialReference = null, W.views.remove(this)), this.notifyChange("spatialReference"), !e && t) this.toolViewManager?.detach(), null != this.analysisViewManager && this.analysisViewManager.detach(), this.layerViewManager?.clear(), this._teardown();else if (e && !t) {
        try {
          this._startup();
        } catch (i) {
          return void queueMicrotask(() => {
            this.fatalError = new a("startup-error", null, i);
          });
        }
        null != this.analysisViewManager && this.analysisViewManager.attach(), this.toolViewManager.attach();
      }
    }, v));
  }
  initialize() {
    this.addResolvingPromise(Promise.all([this.loadAsyncDependencies(), this.validate()]).then(() => (this._isValid = !0, f(() => this.ready)))), this.basemapView = new C({
      view: this
    }), this.layerViewManager = new Z({
      view: this,
      layerViewImporter: {
        importLayerView: e => this.importLayerView(e),
        hasLayerViewModule: e => this.hasLayerViewModule(e)
      },
      supportsGround: this.supportsGround
    }), this.toolViewManager = new P({
      view: this
    }), this._setupSpatialReferenceLogger(), this.selectionManager = new D({
      view: this
    }), this.addHandles([g(() => this.initialExtentRequired, e => this.defaultsFromMap.required = {
      ...this.defaultsFromMap.required,
      extent: e
    }, M), g(() => this.ready, e => {
      this.defaultsFromMap && (this.defaultsFromMap.suspended = e, this.defaultsFromMap.userSpatialReference = e ? this.spatialReference : this._userSpatialReference);
    }, v), g(() => this._userSpatialReference, e => {
      this.defaultsFromMap && (this.defaultsFromMap.userSpatialReference = e);
    }, M)]);
  }
  _setupSpatialReferenceLogger() {
    let e = null;
    this.addHandles([g(() => this.defaultsFromMap?.ready, t => {
      const i = this.map?.allLayers.length > 0;
      if (t && !this.spatialReference && i) {
        if (null != e) return;
        const t = p(() => e = c(e));
        e = r(async t => {
          try {
            await m(this.spatialReferenceWarningDelay, null, t);
          } catch {
            return;
          } finally {
            e = null;
          }
          d.getLogger(this).warn("#spatialReference", "no spatial reference could be derived from the currently added map layers");
        }), this.addHandles(t, "spatial-reference-logger-task");
      } else this.removeHandles("spatial-reference-logger-task");
    }, {
      sync: !0
    })]);
  }
  destroy() {
    this.destroyed || (W.views.remove(this), this.viewEvents.destroy(), this.allLayerViews.destroy(), this.navigation && (this.navigation.destroy(), this._set("navigation", null)), this.graphics = u(this.graphics), this.analyses = u(this.analyses), this.defaultsFromMap.destroy(), this._set("defaultsFromMap", null), u(this.analysisViewManager), this.toolViewManager = u(this.toolViewManager), this.layerViewManager = u(this.layerViewManager), this.selectionManager = u(this.selectionManager), this.basemapView = u(this.basemapView), this.groundView?.destroy(), this.layerViews?.forEach(e => e.destroy()), this.layerViews.length = 0, this.invalidate(), this._emitter.clear(), this.handles.destroy(), this.map = u(this.map), this.updatingHandles.destroy());
  }
  _startup() {
    this._set("ready", !0);
  }
  _teardown() {
    this._set("ready", !1);
  }
  whenReady() {
    return Promise.resolve(this);
  }
  toMap() {
    return d.getLogger(this).error("#toMap()", "Not implemented on this instance of View"), null;
  }
  get activeTool() {
    return this.toolViewManager?.activeTool;
  }
  set activeTool(e) {
    this.toolViewManager && (this.toolViewManager.activeTool = e);
  }
  get animation() {
    return this._get("animation");
  }
  set animation(e) {
    this._set("animation", e);
  }
  get center() {
    return null;
  }
  get defaultsFromMapSettings() {
    return {};
  }
  get defaultsFromMap() {
    return new z({
      required: {
        tileInfo: !1,
        heightModelInfo: !1,
        extent: !1
      },
      map: () => this.map,
      getSpatialReferenceSupport: (e, t) => this.getSpatialReferenceSupport(e, t),
      ...this.defaultsFromMapSettings
    });
  }
  get extent() {
    return this._get("extent");
  }
  set extent(e) {
    this._set("extent", e);
  }
  get heightModelInfo() {
    return this.getDefaultHeightModelInfo();
  }
  get interacting() {
    return this.navigating;
  }
  get navigating() {
    return !1;
  }
  get preconditionsReady() {
    return !(this.fatalError || !this._isValid || this._readyCycleForced || !this.map || h.isLoadable(this.map) && !this.map.loaded || 0 === this.width || 0 === this.height || !this.spatialReference || !this._validateSpatialReference(this.spatialReference) || !this._lockedSpatialReference && !this.defaultsFromMap?.ready || !this.typeSpecificPreconditionsReady);
  }
  get resolution() {
    return 0;
  }
  set map(e) {
    e !== this._get("map") && (e?.destroyed && (d.getLogger(this).warn("#map", "The provided map is already destroyed", {
      map: e
    }), e = null), h.isLoadable(e) && e.load().catch(() => {}), this.constructed && !this.destroyed && (this.forceReadyCycle(), this._lockedSpatialReference = null), this._set("map", e));
  }
  get spatialReference() {
    const e = this._userSpatialReference || this._lockedSpatialReference || this.getDefaultSpatialReference() || null;
    if (e && this.defaultsFromMap?.required?.heightModelInfo) {
      const t = e.clone();
      return t.vcsWkid = this.defaultsFromMap.vcsWkid, t.latestVcsWkid = this.defaultsFromMap.latestVcsWkid, t;
    }
    return e;
  }
  set spatialReference(e) {
    const t = !F(e, this._get("spatialReference"));
    this._set("_userSpatialReference", e), t && (this._set("spatialReference", e), this._spatialReferenceChanged(e));
  }
  _spatialReferenceChanged(e) {}
  get stationary() {
    return !this.animation && !this.navigating && !this.resizing;
  }
  get timeExtent() {
    return this._userTimeExtent ?? this._lockedTimeExtent ?? this.getDefaultTimeExtent() ?? null;
  }
  set timeExtent(e) {
    this._userTimeExtent = e;
  }
  get timeZone() {
    return this._userTimeZone ?? this._lockedTimeZone ?? this.getDefaultTimeZone() ?? k;
  }
  set timeZone(e) {
    this._userTimeZone = e, O(e) || d.getLogger(this).warn("#timeZone", `the parsed value '${e}' may not be a valid IANA time zone`);
  }
  get tools() {
    return this.toolViewManager?.tools;
  }
  get initialExtent() {
    return this.defaultsFromMap?.extent;
  }
  get cursor() {
    return this.toolViewManager?.cursor ?? this._cursor ?? "default";
  }
  set cursor(e) {
    this._cursor = e, this.notifyChange("cursor");
  }
  get size() {
    return [this.width, this.height];
  }
  get effectiveTheme() {
    return this.theme ?? new H();
  }
  whenLayerView(e) {
    return this.layerViewManager?.whenLayerView(e) ?? Promise.reject();
  }
  getDefaultSpatialReference() {
    return this.defaultsFromMap?.spatialReference;
  }
  getDefaultHeightModelInfo() {
    return (this.map && "heightModelInfo" in this.map ? this.map.heightModelInfo : void 0) ?? this.defaultsFromMap?.heightModelInfo ?? null;
  }
  getDefaultTimeZone() {
    return null;
  }
  getDefaultTimeExtent() {
    return null;
  }
  importLayerView(e) {
    throw new a("importLayerView() not implemented");
  }
  hasLayerViewModule(e) {
    return !1;
  }
  async validate() {}
  async loadAsyncDependencies() {}
  invalidate() {
    this._isValid = !1;
  }
  getSpatialReferenceSupport() {
    return {
      constraints: null
    };
  }
  _validateSpatialReference(e) {
    return null != this.getSpatialReferenceSupport(e);
  }
  when(e, t) {
    return this.isResolved() && !this.ready && d.getLogger(this).warn("#when()", "Calling view.when() while the view is no longer ready but was already resolved once will resolve immediately. Use reactiveUtils.whenOnce(() => view.ready).then(...) instead."), super.when(e, t);
  }
  forceReadyCycle() {
    this.ready && (w(() => this.destroyed || !1 === this.preconditionsReady, () => this._readyCycleForced = !1, {
      once: !0
    }), this._readyCycleForced = !0);
  }
  addAndActivateTool(e) {
    this.toolViewManager.tools.add(e), this.activeTool = e;
  }
  tryFatalErrorRecovery() {
    this.fatalError = null;
  }
};
G.views = new s(), e([V()], G.prototype, "_userSpatialReference", void 0), e([V()], G.prototype, "activeTool", null), e([V({
  readOnly: !0
})], G.prototype, "allLayerViews", void 0), e([V()], G.prototype, "groundView", void 0), e([V()], G.prototype, "animation", null), e([V()], G.prototype, "basemapView", void 0), e([V()], G.prototype, "center", null), e([V()], G.prototype, "defaultsFromMapSettings", null), e([V()], G.prototype, "defaultsFromMap", null), e([V()], G.prototype, "fatalError", void 0), e([V({
  type: S
})], G.prototype, "extent", null), e([V(R(b, "graphics"))], G.prototype, "graphics", void 0), e([V(R(x, "analyses"))], G.prototype, "analyses", void 0), e([V({
  readOnly: !0,
  type: E
})], G.prototype, "heightModelInfo", null), e([V({
  readOnly: !0
})], G.prototype, "interacting", null), e([V({
  readOnly: !0
})], G.prototype, "navigating", null), e([V({
  readOnly: !0,
  dependsOn: ["fatalError", "_isValid", "_readyCycleForced", "map", "map.loaded?", "width", "height", "spatialReference", "_lockedSpatialReference", "defaultsFromMap.ready", "typeSpecificPreconditionsReady"]
})], G.prototype, "preconditionsReady", null), e([V({
  readOnly: !0
})], G.prototype, "typeSpecificPreconditionsReady", void 0), e([V({
  type: s,
  readOnly: !0
})], G.prototype, "layerViews", void 0), e([V()], G.prototype, "resolution", null), e([V({
  type: I
})], G.prototype, "magnifier", void 0), e([V({
  value: null,
  type: t
})], G.prototype, "map", null), e([V()], G.prototype, "padding", void 0), e([V({
  readOnly: !0
})], G.prototype, "ready", void 0), e([V({
  type: T
})], G.prototype, "spatialReference", null), e([V()], G.prototype, "spatialReferenceWarningDelay", void 0), e([V()], G.prototype, "stationary", null), e([V({
  readOnly: !0
})], G.prototype, "supportsGround", void 0), e([V({
  type: L
})], G.prototype, "timeExtent", null), e([V({
  type: String,
  nonNullable: !0
})], G.prototype, "timeZone", null), e([V()], G.prototype, "tools", null), e([V()], G.prototype, "toolViewManager", void 0), e([V({
  readOnly: !0
})], G.prototype, "type", void 0), e([V({
  type: Number
})], G.prototype, "scale", void 0), e([V({
  readOnly: !0
})], G.prototype, "updating", void 0), e([V({
  readOnly: !0
})], G.prototype, "initialExtentRequired", void 0), e([V({
  readOnly: !0
})], G.prototype, "initialExtent", null), e([V()], G.prototype, "cursor", null), e([V({
  readOnly: !0
})], G.prototype, "input", void 0), e([V({
  type: A,
  nonNullable: !0
})], G.prototype, "navigation", void 0), e([V()], G.prototype, "layerViewManager", void 0), e([V()], G.prototype, "analysisViewManager", void 0), e([V()], G.prototype, "selectionManager", void 0), e([V()], G.prototype, "width", void 0), e([V()], G.prototype, "height", void 0), e([V({
  readOnly: !0
})], G.prototype, "resizing", void 0), e([V({
  value: null,
  readOnly: !0
})], G.prototype, "size", null), e([V({
  readOnly: !0
})], G.prototype, "suspended", void 0), e([V({
  readOnly: !0
})], G.prototype, "viewEvents", void 0), e([V({
  readOnly: !0
})], G.prototype, "persistableViewModels", void 0), e([V()], G.prototype, "_isValid", void 0), e([V()], G.prototype, "_readyCycleForced", void 0), e([V()], G.prototype, "_lockedSpatialReference", void 0), e([V()], G.prototype, "_userTimeZone", void 0), e([V()], G.prototype, "_lockedTimeZone", void 0), e([V()], G.prototype, "_userTimeExtent", void 0), e([V()], G.prototype, "_lockedTimeExtent", void 0), e([V({
  type: H
})], G.prototype, "theme", void 0), e([V({
  readOnly: !0,
  type: H
})], G.prototype, "effectiveTheme", null), G = W = e([_("esri.views.View")], G);
const N = globalThis.$arcgis;
N && !N.views && Object.defineProperty(N, "views", {
  configurable: !1,
  enumerable: !0,
  writable: !1,
  value: G.views
});
const $ = G;
function B(e) {
  return e.layerViews;
}
export { $ as default };