/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import e from "../../../../../core/Evented.js";
import has from "../../../../../core/has.js";
import { r as t } from "../../../../../chunks/rbush.js";
import { width as s, height as i, create as o } from "../../../../../geometry/support/aaBoundingRect.js";
import { getInfo as n } from "../../../../../geometry/support/spatialReferenceUtils.js";
import { Tile as r } from "./Tile.js";
import l from "../../../tiling/TileCoverage.js";
import h from "../../../tiling/TileKey.js";
const d = {
    added: [],
    removed: []
  },
  a = new Set(),
  c = new h(0, 0, 0, 0);
function m(e, t) {
  const s = n(t);
  if (!s) return null;
  const [i, r] = s.valid;
  return e[2] > r ? [o([e[0], e[1], r, e[3]]), o([i, e[1], i + e[2] - r, e[3]])] : e[0] < i ? [o([i, e[1], e[2], e[3]]), o([r - (i - e[0]), e[1], r, e[3]])] : null;
}
class u extends e {
  constructor(e) {
    super(), this._tiles = new Map(), this._index = t(9, has("esri-csp-restrictions") ? e => ({
      minX: e.bounds[0],
      minY: e.bounds[1],
      maxX: e.bounds[2],
      maxY: e.bounds[3]
    }) : [".bounds[0]", ".bounds[1]", ".bounds[2]", ".bounds[3]"]), this.tiles = [], this.tileScheme = e;
  }
  destroy() {
    this.clear();
  }
  clear() {
    this.tiles.length = 0, this._tiles.clear(), this._index.clear();
  }
  has(e) {
    return this._tiles.has(e);
  }
  get(e) {
    return this._tiles.get(e);
  }
  getIntersectingTiles(e) {
    if (!e || 0 === s(e) || 0 === i(e)) return [];
    const t = m(e, this.tileScheme.spatialReference);
    return null != t ? [...new Set([...this.boundsIntersections(t[0]), ...this.boundsIntersections(t[1])])] : this.boundsIntersections(e);
  }
  boundsIntersections(e) {
    return this._index.search({
      minX: e[0],
      minY: e[1],
      maxX: e[2],
      maxY: e[3]
    });
  }
  updateTiles(e) {
    const t = {
      added: [],
      removed: []
    };
    for (const s of e.added) if (!this.has(s)) {
      const e = new r(this.tileScheme, s);
      this._tiles.set(s, e), this._index.insert(e), t.added.push(e);
    }
    for (const s of e.removed) if (this.has(s)) {
      const e = this.get(s);
      this._tiles.delete(s), this._index.remove(e), t.removed.push(e);
    }
    this.tiles.length = 0, this._tiles.forEach(e => this.tiles.push(e)), (t.added.length || t.removed.length) && this.emit("update", t);
  }
  setViewState(e) {
    const t = this.tileScheme.getTileCoverage(e, 0);
    if (!t) return;
    const {
        spans: s,
        lodInfo: i
      } = t,
      {
        level: o
      } = i;
    if (s.length > 0) for (const {
      row: n,
      colFrom: l,
      colTo: h
    } of s) for (let e = l; e <= h; e++) {
      const t = c.set(o, n, i.normalizeCol(e), i.getWorldForColumn(e)).id;
      if (a.add(t), !this.has(t)) {
        const e = new r(this.tileScheme, t);
        this._tiles.set(t, e), this._index.insert(e), this.tiles.push(e), d.added.push(e);
      }
    }
    for (let n = this.tiles.length - 1; n >= 0; n--) {
      const e = this.tiles[n];
      a.has(e.id) || (this._tiles.delete(e.id), this.tiles.splice(n, 1), this._index.remove(e), d.removed.push(e));
    }
    (d.added.length || d.removed.length) && this.emit("update", d), l.pool.release(t), a.clear(), d.added.length = 0, d.removed.length = 0;
  }
}
export { u as default };